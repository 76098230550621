import React, { useState } from 'react';

export interface PropertiesCustom {
    name: string;
    value: string;
    label: string;
    onChange?: (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
}

export const CheckBoxAgendaCustom = (props: PropertiesCustom) => {
    const [isChecked, setIsChecked] = useState(true); 

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsChecked(event.target.checked); 
        if (props.onChange) {
            props.onChange(event);
        }
    };

    return (
        <div className="flex items-center mb-4">
            <input
                id={props.name}
                type="checkbox"
                value={props.value}
                checked={isChecked} 
                onChange={handleChange}
                name={props.name}
                className="w-4 h-4 text-blue-900 bg-gray-100 border-gray-300 rounded focus:ring-blue-900 dark:focus:ring-blue-900 dark:ring-offset-gray-100 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-100 dark:border-gray-900"
            />
            <label className="ml-2 text-sm text-gray-900 dark:text-gray-900">
                {props.label}
            </label>
        </div>
    );
};
