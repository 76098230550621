import styled from 'styled-components';

export const KonvaContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

export const KonvaTitle = styled.div`
  width: calc(100% - 15px);
  text-align: start;
  font-size: 15px;
  font-weight: 700;
  color: #333333;
  margin-bottom: 10px;
  padding-bottom: 6px;
  border-bottom: 1px solid #ccced1;
`;

export const KonvaCommands = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: calc(100% - 15px);
  margin-bottom: 15px;
  border-bottom: 1px solid #ccced1;
`;

export const KonvaButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: transparent;
  cursor: pointer;
  margin: 0 5px;
`;

export const KonvaColor = styled.div`
  width: 15px;
  height: 15px;
  cursor: pointer;
  margin: 0 5px;
`;

export const KonvaColorSpan = styled.span`
  display: block;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 1px solid #959292a0;
`;

export const KonvaColorInput = styled.input`
  width: 0;
  height: 0;
  visibility: hidden;
`;

export const KonvaIcon = styled.svg`
  height: fit-content;
  ${({ size }) => size && `height: ${size ?? 20}px; min-width: ${size ?? 20}px;`}
`;

export const KonvaSeparator = styled.div`
  margin: 3.9px;
  width: 1px;
  height: 25px;
  background-color: #ccced1;
`;

export const KonvaModalFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: calc(100% - 15px);
`;

export const KonvaModalButton = styled.button`
  padding: 2.35px 7.8px;
  margin-left: 12px;
  border: none;
  border-radius: 5px;
  font-size: 13px;
  line-height: 24px;
  cursor: pointer;

  ${({ $variant }) =>
    $variant === 'add' && `
      background-color: #a7eb51;
      color: #ffffff;
    `}
  
  ${({ $variant }) =>
    $variant === 'cancel' && `
      background-color: transparent;
      color: #333333;
    `}
`;

export const KonvaBoard = styled.div`
  margin-bottom: 20px;
  border: 2px solid #696666;
  border-radius: 7px;
  padding: 5px;
`;