import React, { Component } from "react";
import { TittleCustom } from "../../../../../components/TittleCustom";
import { ButtonReturnCustom } from "../../../../../components/ButtonReturnCustom";
import { ButtonSaveCustom } from "../../../../../components/ButtonSaveCustom";
import InputCustom from "../../../../../components/InputCustom";
import { pessoa_emailModel } from "../../../../../resource/request";
import api_app from "../../../../../apis/api_app";
import withRouter from "../../../../../helpers/withRouter";
import { store } from "../../../../../redux/store";
import { ToggleCustom } from "../../../../../components/ToggleCustom";

interface State {
    email: pessoa_emailModel
    msg: string,
    isSubmitted: boolean;
    emailPrincipal: pessoa_emailModel;
}

export default withRouter(class CreateEmail extends Component<any> {

    state: State = {
        email: {
            pessoa_id: store.getState().user.user.id,
            flg_visivel: "S",
            flg_principal: "N",
            flg_status: "A",
            dat_criado_em: new Date(),
        } as pessoa_emailModel,
        msg: "",
        emailPrincipal: {} as pessoa_emailModel,
        isSubmitted: false,
    }

    componentDidMount() {
        store.getState().user.user.pessoa_email.map(email => {
            if (email.flg_principal === "S") {
                this.setState({emailPrincipal: email });
            }
        })
    }

    handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = event.target;

            this.setState({
                email: {
                    ...this.state.email,
                    [name]: value
                }
            });
            console.log(this.state.emailPrincipal);

    };

    handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        api_app.criarEmail(this.state.email.id, this.state.email).then(x => {
            const msg = "Seu email foi cadastrado com com suscesso!";
            this.setState({ msg, isSubmitted: true });
            this.props.updateStore();
        });
    };

    render() {
        return <>
            <form onSubmit={this.handleSubmit}>
                <div className="flex items-center justify-between rounded-t dark:border-gray-600">
                    <TittleCustom
                        name="Adicionar E-mail"
                    />
                    <ButtonReturnCustom
                        link="/perfil"
                    />
                </div>
                <div className="grid gap-6 mb-6">
                    <InputCustom
                        label="E-mail:"
                        value={this.state?.email?.email || ''}
                        type="text"
                        name="email"
                        placeholder="E-mail"
                        onChange={this.handleChange}
                        required
                    />
                    <InputCustom
                        label="Observação:"
                        value={this.state?.email?.observacao || ''}
                        type="text"
                        name="observacao"
                        placeholder="Observação"
                        onChange={this.handleChange}
                    />
                    <ToggleCustom
                        label={this.state.emailPrincipal?.email !== undefined ? this.state.emailPrincipal?.email + " é seu email principal!" : "e-mail Principal?"}
                        value={this.state?.email.flg_principal || 'S'}
                        name='flg_principal'
                        onChange={this.handleChange}
                        disabled={this.state?.emailPrincipal?.email !== undefined ? true : false}
                        defaultValue={this.state?.email.flg_principal || 'N'}
                    />
                </div>
                <div className="flex items-center mt-6 space-x-4 rtl:space-x-reverse">
                    {!this.state.isSubmitted && (
                        <ButtonSaveCustom
                            type="submit"
                            name="Salvar"
                        />
                    )}
                </div>
                <p className="mt-2 text-sm text-green-600 dark:text-green-500">{this.state.msg}</p>
            </form>
        </>
    }
})