import React, { useState, useEffect } from "react";
import SelectCustom, { Option } from "../../../../../components/SelectCustom";
import api_app from "../../../../../apis/api_app";
import { profissaoModel } from "../../../../../resource/request";

interface Props {
    value?: string;
    valueProfissao?: string;
    onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
    profissao_id?: number | null;
}

const SelectEspecialidadeCustum: React.FC<Props> = ({ value = "Selecione", valueProfissao = "Selecione", onChange, profissao_id = 0 }) => {
    const [especialidades, setEspecialidades] = useState<Option[]>([{ key: "1", value: "Carregando...", disabled: true }]);
    const [selectedEspecialidade, setSelectedEspecialidade] = useState<string>(value);
    const [profissao, setProfissao] = useState<Option[]>([{ key: "1", value: "Carregando...", disabled: true }]);
    const [selectedProfissao, setSelectedProfissao] = useState<string>(valueProfissao);
    const [disableEspecialidades, setDisableEspecialidade] = useState<boolean>(true)
    const [shouldReset, setShouldReset] = useState<boolean>(false)

    useEffect(() => {
        buscarDadosDoBanco();
    }, [profissao_id]);

    useEffect(() => {
        if(value != "Selecione") {
            setSelectedEspecialidade(value);
        }
        if(valueProfissao != "Selecione"){
            setSelectedProfissao(valueProfissao);
            setDisableEspecialidade(false);
        }
    }, []);

    const getEspecialidades = async (): Promise<any[]> => {
        return await api_app.findEspecialidades();
    };

    const buscarEspecialidades = async (profissao_id: number|null) => {
        try {
            const especialidades = await getEspecialidades();
            setEspecialidades(filtrarEspecialidades(especialidades, profissao_id));
        } catch (error) {
            console.error("Erro ao buscar especialidades:", error);
        }
    };

    const filtrarEspecialidades = (especialidades: any[], profissao_id: any): Option[] => {
        if (profissao_id !== 0) {
            return especialidades
                .filter((F: any) => F.profissao_id === profissao_id)
                .map((especialidade: any) => ({
                    key: especialidade.id.toString(),
                    value: especialidade.especialidade,
                    disabled: false,
                }));
        } else {
            return especialidades.map((especialidade: any) => ({
                key: especialidade.id.toString(),
                value: especialidade.especialidade,
                disabled: false,
            }));
        }
    };

    const buscarDadosDoBanco = async () => {
        const profissaResponse = await getProfissao();
        const profissaoOptions = profissaResponse.map((profissao: any) => ({
            key: profissao.id.toString(),
            value: profissao.tipo_profissao,
            disabled: false,
        }));
        setProfissao(profissaoOptions);

        await buscarEspecialidades(profissao_id);
    };

    const getProfissao = async (): Promise<any[]> => {
        try {
            return await api_app.findProfissao();
        } catch (error) {
            console.error("Erro ao obter as profissões:", error);
            throw error;
        }
    };

    const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const { name, value } = event.target;

        if (name === "profissao") {
            setSelectedProfissao(value);
            setDisableEspecialidade(false);
            buscarEspecialidades(parseInt(value));
            setShouldReset(true);
        } else {
            setShouldReset(false);
        }

        if (onChange) {
            onChange(event);
        } else {
            setSelectedEspecialidade(value);
        }
    };

    return (
        <>
            <SelectCustom
                label="Profissão:"
                name="profissao"
                defaultValue={selectedProfissao || "Selecione"}
                options={profissao}
                onChange={handleChange}
            />

            <SelectCustom
                label="Especialidades:"
                name="especialidade_id"
                defaultValue={selectedEspecialidade || ""}
                onChange={handleChange}
                options={especialidades}
                shouldReset={shouldReset}
                disabled={disableEspecialidades}
            />
        </>
    );
};

export default SelectEspecialidadeCustum;
