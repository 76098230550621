import React, { useState } from "react";
import { TittleCustom } from "../../../components/TittleCustom";
import { ButtonSaveCustom } from "../../../components/ButtonSaveCustom";
import api_app from "../../../apis/api_app";
import SelectEspecialidadeCustum from "../meuperfil/dadosprofissionais/especialidades/SelectEspecialidadesCustum";
import { Pagination } from "flowbite-react";
import SpinnerCustom from "../../../components/SpinnerCustom";
import { AboutCustumPage } from "../../../components/AboutCustom";
import SelectCustom from "../../../components/SelectCustom";
import {
    parseDateToDMYFormat,
    extractTimeFromDateString,
    increaseTimeOnDate
} from "../../../helpers/date_helpers";
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";

const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

export const ProntoAtendimento = () => {
  const pessoa = useSelector((state) => state.user.user);
  let navigate = useNavigate();

  const [agendas, setAgendas] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [profissaoModel, setProfissaoModel] = useState({});
  
  const [especialidades, setEspecialidades] = useState({
    pessoa_id: pessoa?.id,
    flg_status: 'A',
    flg_visivel: 'S',
    dat_criado_em: new Date(),
    num_especialidade_rqe: '',
    valor_atendimento_pa: 0,
    vlr_atendimento: 0,
  });
  
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(50);
  const [totalpg, setTotalpg] = useState(50);
  const [filter, setFilter] = useState({});
  const [loading, setLoading] = useState(true);
  const [agendaSelecionada, setAgendaSelecionada] = useState({});
  const [confirmMessage, setConfirmMessage] = useState('');
  const [especialidade, setEspecialidade] = useState([]);
  const [selectedEspecialidade, setSelectedEspecialidade] = useState('');

  const getFilter = () => {
    const inicialDate = new Date();
    const finalDate = increaseTimeOnDate(inicialDate, '04:00')

    const obj = {
      inicial_date: inicialDate,
      final_date: finalDate,
      especialidades: filter.especialidades,
      page: currentPage,
      limit: perPage || 50
    };

    return obj
  }

  const fetchAgendas = (page) => {
    api_app.getAgenda(getFilter()).then((resp) => {
      const ordenedAppointmentsByDates = resp.data.data.sort((a, b) => {
        return a.data > b.data ? 1 : -1
      })
      setAgendas(ordenedAppointmentsByDates)
      setTotalpg(resp.data.totalPages)
      setCurrentPage(page)
      setLoading(false)
    });
  };

  useState(() => {
    fetchAgendas(1);
  }, [])

  const toggleModal = (id) => {
    const agenda = agendas.find((x) => x.id === id);
    if (agenda) {
      setShowModal(true)
      setAgendaSelecionada(agenda)
    }
  }

  const closeModal = () => {
    setShowModal(false)
  }

  const filterAgendas = (event) => {
    event.preventDefault();
    setLoading(true)
    api_app.getAgenda(getFilter()).then((resp) => {
      setAgendas(resp.data.data)
      setTotalpg(resp.data.totalPages)
      setCurrentPage(1)
      setLoading(false)
    }).catch((error) => {
      console.error('Erro ao buscar agendas:', error);
    });
  };

  const fetchEspecialidades = () => {
    const especialidade = agendaSelecionada.pessoa?.pessoa_especialidade.map((esp) => ({
      key: esp.especialidade_id?.toString() || '',
      value: esp?.especialidade?.especialidade?.toString() || '',
      disabled: false,
    })) || [];

    setEspecialidade(especialidade);
  };

  const handleChangeEspecialidade = (event) => {
    const { name, value } = event.target;
    setEspecialidades({
      ...especialidades,
      [name]: value
    })
    setFilter({
      ...filter,
      especialidades: value
    })
  };

  const handleChangePage = (page) => {
    setCurrentPage(page)
    fetchAgendas(page)
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    setSelectedEspecialidade((prevState) => ({
      ...prevState,
      [name]: value
    }))
  };

  const handleSubmitModal = async (event) => {
    event.preventDefault();

    if (!agendaSelecionada.id) {
      setConfirmMessage('Agenda selecionada não encontrada.')
      return;
    }
    if (!selectedEspecialidade) {
      setConfirmMessage('Por favor, selecione uma especialidade.')
      return;
    }
    try {
      await api_app.patchAgenda(agendaSelecionada.id, {
        id: agendaSelecionada.id,
        agendado_por: agendaSelecionada.id,
        status_agenda_id: 3,
        especialidade_id: parseInt(selectedEspecialidade),
        paciente_id: pessoa.id,
      });
      setConfirmMessage('Consulta confirmada com sucesso!')
      fetchAgendas(currentPage);
      navigate('/agendamento-de-consulta');
    } catch (error) {
      console.error('Erro ao confirmar consulta:', error);
      setConfirmMessage('Erro ao confirmar consulta.')
    }
  }

  const dataFormatada = parseDateToDMYFormat(new Date(agendaSelecionada?.data));
  const horarioFormatado = extractTimeFromDateString(new Date(agendaSelecionada?.data));

  return (
    <>
      <div>
        <TittleCustom name="Pronto Atendimento" />
      </div>
      <form onSubmit={filterAgendas}>
        <div className="grid gap-6 md:grid-cols-2">
          <SelectEspecialidadeCustum
            profissao_id={profissaoModel?.id || 0}
            value={especialidades?.id?.toString() || ""}
            onChange={handleChangeEspecialidade}
          />
          <div>
            <button
              type="submit"
              className="inline-flex items-center py-2.5 px-3 ms-2 text-sm font-medium text-white bg-blue-900 rounded-lg border border-blue-800 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-900 dark:hover:bg-blue-800 dark:focus:ring-blue-800"
            >
              <svg
                className="w-4 h-4 me-2"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                />
              </svg>
              Pesquisar
            </button>
          </div>
        </div>
      </form>
      {loading ? (
        <div className="flex justify-center items-center h-screen bg-gray-100">
          <SpinnerCustom />
        </div>
      ) : (
        <div>
          <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    Data
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Hora
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Médico
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Especialidade
                  </th>
                  <th scope="col" className="px-6 py-3  text-center">
                    Opções
                  </th>
                </tr>
              </thead>
              <tbody>
                {agendas.map((agenda) => (
                  <tr key={agenda.id} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                    <td scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                      {agenda.data ? parseDateToDMYFormat(new Date(agenda.data)) : '-'}
                    </td>
                    <td className="px-6 py-4">
                      {agenda.data ? extractTimeFromDateString(new Date(agenda.data)) : '-'}
                    </td>
                    <td className="px-6 py-4">
                      {agenda.pessoa?.nome}
                    </td>
                    <td className="px-6 py-4">
                      {agenda?.pessoa?.pessoa_especialidade?.map((z, index) => {
                        let separator = ''
                        if (index >= 1) separator = ' - '
                        return (
                          <span key={index}>{separator}{z.especialidade?.especialidade}</span>
                        )
                      })}
                    </td>
                    <td className="flex items-center justify-center">
                      <button
                        data-modal-target="default-modal"
                        data-modal-toggle="default-modal"
                        className="inline-flex items-center py-2.5 px-3 ms-2 text-sm font-medium text-white bg-blue-800 hover:bg-blue-900 focus:ring-4 rounded-lg"
                        style={{ backgroundColor: "#3b5179" }}
                        onClick={() => toggleModal(agenda.id)}
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 mr-2">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z" />
                        </svg>
                        Consultar
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="flex items-center justify-end mt-4">
            <div className="flex overflow-x-auto sm:justify-center">
              <Pagination
                currentPage={currentPage}
                totalPages={totalpg}
                onPageChange={handleChangePage}
                showIcons previousLabel="anterior"
                nextLabel="próxima"
              />
            </div>
          </div>
        </div>
      )}
      {showModal && (
        <div className="fixed top-0 right-0 left-0 bottom-0 z-50 flex items-center justify-center bg-gray-500 bg-opacity-75">
          <div id="default-modal" className="w-full max-w-3xl p-4">
            <div className="relative bg-white rounded-lg shadow dark:bg-gray-700 w-full h-auto max-h-[90vh] overflow-y-auto">
              <div className="flex items-center justify-between p-4 md:p-3 border-b rounded-t dark:border-gray-600">
                <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                  Agendamento de Consulta
                </h3>
                <button
                  type="button"
                  className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                  onClick={closeModal}
                >
                  <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" stroke="currentColor" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                  </svg>
                  <span className="sr-only">Fechar modal</span>
                </button>
              </div>
              <form onSubmit={handleSubmitModal} onLoad={fetchEspecialidades}>
                <div className="p-4 md:p-5 space-y-4">
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <div className="flex flex-col justify-center items-center md:col-span-1">
                      <AboutCustumPage
                        label={agendaSelecionada?.pessoa?.nome || ""}
                        name={agendaSelecionada?.pessoa?.pessoa_profissional?.find(() => true)?.resumo_curriculo || ""}
                        src={
                          agendaSelecionada?.pessoa?.pessoa_documento?.find(x => x.tipo_documento_id === 7) ?
                            REACT_APP_BASE_URL + "/pessoa-documento/documento/" + agendaSelecionada?.pessoa?.pessoa_documento?.find(x => x.tipo_documento_id === 7)?.id
                            : ''}
                        pessoaprofission={agendaSelecionada?.pessoa?.pessoa_fisica?.find(() => true) || {}}
                      />
                    </div>
                    <div className="flex flex-col justify-center items-center md:col-span-1">
                      <div className="w-full">
                        <div className="grid gap-2 mb-2">
                          <label className="block  text-sm font-medium text-gray-900 dark:text-white">Data:</label>
                          <input
                            value={dataFormatada}
                            disabled
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                          />
                          <label className="block  text-sm font-medium text-gray-900 dark:text-white">Hora:</label>
                          <input
                            value={horarioFormatado}
                            disabled
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                          />
                          <SelectCustom
                            label="Especialidade:"
                            name="selectedEspecialidade"
                            defaultValue="Selecione"
                            options={especialidade}
                            onChange={handleChange}
                          />
                          {confirmMessage && (
                            <div className="mt-4">
                              <p className="mt-2 text-sm text-green-600 dark:text-green-500">{confirmMessage}</p>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex items-center justify-between p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
                  <div className="ml-auto">
                    <ButtonSaveCustom
                      name="Confirmar"
                      type="submit"
                    />
                  </div>
                  <button
                    type="button"
                    className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 font-medium rounded-lg text-sm px-5 py-2.5 ml-2 mb-2"
                    onClick={closeModal}
                  >
                    Cancelar
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
}