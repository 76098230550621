import styled from "styled-components";

export const MainContainer = styled.div`
	font-family: 'Lato';
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	color: black;
`

export const CkEditorContainer = styled.div`
	min-width: 795px;
	color: black;
`

export const CkEditorInnerContainer = styled(CkEditorContainer)``

export const CkEditorWrapper = styled.div`
  .ck-content {
    ul,
    ol {
      list-style-position: outside;
      padding-left: 30px;
      margin-left: 0;
    }

    li {
      padding-left: 10px;
    }

    blockquote {
      margin-left: 0;
      padding-left: 30px;
    }

    h2 {
        font-size: 28px;
    }

    h3 {
        font-size: 24px;
    }

    h4 {
        font-size: 20px;
    }
  }
`

export const SaveButton = styled.button`
    box-sizing: border-box;
    background-color: rgb(167, 235, 81);
    color: white;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    font-family: 'Open Sans', 'Lato', sans-serif;
    line-height: 20px;
    padding: 10px 20px;
    margin: 15px 0 0;
    border: 1px solid white;
    border-radius: 8px;
`