import { Textarea } from "flowbite-react";
import React from "react";

export interface PropertiesCustom {
    label: string;
    value: string;
    name: string;
    onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void; 
    required?: boolean;
    disabled?: boolean;

}

export const TextAreaCustom: React.FC<PropertiesCustom> = (props) => {
    return (
        <div>
            <div className="flex items-center justify-between p-4 md:p-5 rounded-t dark:border-gray-600">
                <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                    {props.label}
                </h3>
            </div>
            <div>
                <Textarea
                    placeholder="Escreva Aqui!"
                    value={props.value}
                    name={props.name}
                    cols={50}
                    onChange={props.onChange}
                    required={props.required}
                    disabled={props.disabled}
                    maxLength={4000}
                />
            </div>
        </div>
    );
};