import { Checkbox } from "flowbite-react";
import { Component } from "react";
import { TittleCustom } from "../../../../../components/TittleCustom";
import { ButtonCreateCustom } from "../../../../../components/ButtonCreateCustom";
import { ButtonEditCustom } from "../../../../../components/ButtonEditCustom";
import { ButtonDeleteCustom } from "../../../../../components/ButtonDeleteCustom";
import ButtonSearchCuston from "../../../../../components/ButtonSearchCustom";

export class DadosDoPix extends Component {
    render() {
        return <>
            <div className="text-lg font-semibold text-gray-900 dark:text-white">
                <TittleCustom name=" Dados De Pix" />
            </div>
            <div className="flex flex-column sm:flex-row flex-wrap space-y-4 sm:space-y-0 items-center justify-between pb-4">
                <ButtonCreateCustom link="/adicionar-dados-do-pix" name="Adicionar Dados Pix" />
                <ButtonSearchCuston />
            </div>
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                            <th scope="col" className="p-4">
                                <Checkbox />
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Instituição Financeira
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Chave Pix
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Titular
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Descrição
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Opções
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                            <td className="w-4 p-4">
                                <Checkbox />
                            </td>
                            <td scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                Mercado Pago
                            </td>
                            <td className="px-6 py-4">
                                82393397000
                            </td>
                            <td className="px-6 py-4">
                                Jussye Da Silva Marques
                            </td>
                            <td className="px-6 py-4">
                                Pix Da Conta Particular
                            </td>
                            <td className="px-6 py-4">
                                <div className="flex items-center">
                                    <ButtonEditCustom link="/alterar-dados-do-pix" />
                                    <ButtonDeleteCustom link="/" />
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </>
    }
}