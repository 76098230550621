import styled from 'styled-components';

export const KonvaResizeModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 300px;
  max-height: 200px;
`;

export const KonvaResizeContent = styled.div`
  width: calc(100% - 15px);
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: 10px 0 20px;
`

export const KonvaResizeInputsContainer = styled.div`
  position: relative;
  width: 35%;
`

export const KonvaResizeInput = styled.input`
  width: 100%;
  height: 30px;
  border: 1px solid #adadad;
  border-radius: 7px;
  padding: 8px 25px 8px 17px;
  box-sizing: border-box;
  height: 36px;
  background-color: #ebebeb;
  color: #212121;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`

export const KonvaResizeInputMetric = styled.span`
  position: absolute;
  font-size: 13px;
  font-weight: 300;
  bottom: 6px;
  right: 13px;
  color: #212121;
`