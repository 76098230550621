import React, { Component } from "react";
import { ButtonSaveCustom } from "./ButtonSaveCustom";
import { pessoaModel, tipo_documentoModel } from "../resource/request";
import { createUser } from "../redux/user/slice";
import { store } from "../redux/store";
import api_app from "../apis/api_app";
import api_app_upload from "../apis/apiUpload";
import withRouter from "../helpers/withRouter";

interface State {
    pessoa: pessoaModel;
    msg: string;
    formFiles: FormData;
    AddDocumento?: {
        pessoa_id: number;
        tipo_documento_id?: string[];
        id?: number;
        source?: string;
    };
    selectedFile: File | null;
    saveFile: boolean;
    linklToFile?: string;
}

class FilePhotoCustom extends Component<any, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            pessoa: store.getState().user?.user,
            msg: '',
            formFiles: new FormData(),
            AddDocumento: {
                pessoa_id: store.getState().user.user.id,
                tipo_documento_id: []
            },
            selectedFile: null,
            saveFile: false,
            linklToFile: '/FotoUser.jpg'
        };
    }

    componentDidMount() {
        const documentos = this.state.pessoa?.pessoa_documento?.filter(x => x.tipo_documento_id == 7) || [];
        const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;
        if (documentos.length){
            const image = documentos.length > 0 ? documentos[0] : null;
                this.setState({
                    linklToFile: REACT_APP_BASE_URL+"/pessoa-documento/documento/"+image?.id
                });
        }
    }

    handleChangeFiles = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            const f = this.state.formFiles;
            const tipo = event.target.name;
            const data = this.state.AddDocumento;
            if (!data?.tipo_documento_id?.includes(tipo)) {
                data?.tipo_documento_id?.push(tipo);
            }
            f.append('files', file);
            this.setState({ formFiles: f, AddDocumento: data });
        }
    };

    async sendFiles(numberPessoaID: number) {
        if (this.state.formFiles.has('files')) {
            const f = this.state.formFiles;
            const jsonData = JSON.stringify(this.state.AddDocumento);
            f.append('createFileDto', jsonData);
            try {
                await api_app_upload.register(f);
                this.setState({ msg: 'Imagem salva com sucesso', saveFile: false } );
            } catch (error) {
                console.error("Erro ao enviar Imagem:", error);
                this.setState({ msg: 'Erro ao enviar Imagem' });
            }
        }
    }

    handleSubmitFiles = async (event: React.FormEvent<HTMLButtonElement>) => {
        event.preventDefault();
        if (this.state.pessoa?.id == null) {
            try {
                const result = await api_app.findByPessoa(this.props.router.keycloak?.idTokenParsed?.email);
                this.props.router.dispatch(createUser(result));
                await this.sendFiles(result.pessoa_id);
            } catch (error) {
                console.error("Erro ao enviar Imagem:", error);
            }
        } else {
            await this.sendFiles(this.state.pessoa.id);
        }
        this.props.router.updateStore();
    }

    handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            this.setState({ selectedFile: file, saveFile: true });
            this.handleChangeFiles(event)
        }
    };

    render() {
        return (
            <>
                <div className="flex items-center justify-center">
                    <div className="w-full max-w-sm rounded-lg">
                        <div className="flex items-center justify-center p-8">
                            <label
                                htmlFor="dropzone-file"
                                className="flex flex-col items-center justify-center w-full rounded-lg cursor-pointer bg-transparent dark:bg-transparent hover:bg-transparent dark:hover:bg-transparent"
                            >
                                <div className="flex flex-col items-center justify-center pt-5">
                                    {this.state.selectedFile ? (
                                        <img
                                            className="rounded-lg mb-2"
                                            src={URL.createObjectURL(this.state.selectedFile)}
                                            alt="user"
                                            style={{ width: "200px", height: "200px" }}
                                        />
                                    ) : (
                                        <img
                                            className="rounded-lg mb-2"
                                            src={this.state.linklToFile}
                                            alt="user"
                                            style={{ width: "200px", height: "200px" }}
                                        />
                                    )}
                                    <p className="text-sm text-gray-500 dark:text-gray-400 mb-2">
                                        <span className="font-semibold">
                                            Alterar Imagem
                                        </span>
                                    </p>
                                    <p className="text-xs text-gray-500 dark:text-gray-400 mb-3">
                                        SVG, PNG, JPG (MAX. 800x400px)
                                    </p>
                                    {this.state.saveFile &&
                                        <button
                                            type="button"
                                            name="Salvar"
                                            onClick={this.handleSubmitFiles}
                                            className="text-white bg-green-500 hover:bg-green-600 focus:ring-4 border border-white font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2"
                                            style={{ backgroundColor: '#a7eb51' }}
                                        >
                                            Salvar
                                        </button>
                                    }
                                    <p className="mt-2 text-sm text-green-600 dark:text-green-500">
                                        {this.state.msg}
                                    </p>
                                </div>
                            </label>
                            <input
                                id="dropzone-file"
                                type="file"
                                className="hidden"
                                name="7"
                                onChange={this.handleFileChange}
                            />
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default withRouter(FilePhotoCustom);
