import React from "react";
import { KonvaButton, KonvaIcon } from "../konvaSketchBoard/styles";

const KonvaTextButton = ({ openInputTextModal }) => {
    return (
        <KonvaButton onClick={openInputTextModal}>
            <KonvaIcon
            size={20}
            viewBox="0 0 20 20"
            >
            <path fill="#000000" d="M8.69 14.915c.053.052.173.083.36.093a.366.366 0 0 1 .345.485l-.003.01a.738.738 0 0 1-.697.497h-2.67a.374.374 0 0 1-.353-.496l.013-.038a.681.681 0 0 1 .644-.458c.197-.012.325-.043.386-.093a.28.28 0 0 0 .072-.11L9.592 4.5H6.269c-.359-.017-.609.013-.75.09-.142.078-.289.265-.442.563-.192.29-.516.464-.864.464H4.17a.43.43 0 0 1-.407-.569L4.46 3h13.08l-.62 2.043a.81.81 0 0 1-.775.574h-.114a.486.486 0 0 1-.486-.486c.001-.284-.054-.464-.167-.54-.112-.076-.367-.106-.766-.091h-3.28l-2.68 10.257c-.006.074.007.127.038.158zM3 17h8a.5.5 0 1 1 0 1H3a.5.5 0 1 1 0-1z"></path>
            </KonvaIcon>
        </KonvaButton>
    )
}

export default KonvaTextButton