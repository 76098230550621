import axios from "axios";
import {store} from "../redux/store";
const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;
//const REACT_APP_BASE_URL = "https://app.sec24.com.br/server";


const apis = axios.create({
    baseURL: REACT_APP_BASE_URL,
});

apis.interceptors.request.use(async (config) => {
    // if (store.getState().usuario) {
    //     const { token } = store.getState().usuario;
    //     config.headers.Authorization = `Bearer ${token}`;
    // }
    return config;
});

export default  apis;

