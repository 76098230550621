import React, { useRef, useEffect } from "react";
import { Image as KonvaImage, Transformer } from "react-konva";
import useImage from "use-image";

const ResizableImage = ({ image, isSelected, onSelect, onChange }) => {
    const shapeRef = useRef();
    const trRef = useRef();
    const [img] = useImage(image.src);
  
    useEffect(() => {
      if (isSelected) {
        trRef.current.nodes([shapeRef.current]);
        trRef.current.getLayer().batchDraw();
      }
    }, [isSelected]);
  
    return (
      <>
        <KonvaImage
          image={img}
          ref={shapeRef}
          {...image}
          draggable={isSelected ? true : false}
          onClick={onSelect}
          onTap={onSelect}
          onTransformEnd={(e) => {
            const node = shapeRef.current;
            const scaleX = node.scaleX();
            const scaleY = node.scaleY();
  
            node.scaleX(1);
            node.scaleY(1);
            onChange({
              ...image,
              x: node.x(),
              y: node.y(),
              rotation: node.rotation(),
              width: node.width() * scaleX,
              height: node.height() * scaleY,
            });
          }}
          onDragEnd={(e) => {
            onChange({
              ...image,
              x: e.target.x(),
              y: e.target.y(),
            });
          }}
        />
        {isSelected && (
          <Transformer
            ref={trRef}
            boundBoxFunc={(oldBox, newBox) => {
              if (newBox.width < 50 || newBox.height < 50) {
                return oldBox;
              }
              return newBox;
            }}
          />
        )}
      </>
    );
  };

export default ResizableImage