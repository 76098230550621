import { useState, useEffect, useRef } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import KonvaSketchModal from '../konvaSketchModal/KonvaSketchModal'
import KonvaSketchBoard from '../konvaSketchModal/konvaSketchBoard/KonvaSketchBoard'
import {
  ClassicEditor,
  Bold,
  Italic,
  Table,
  TableCaption,
  TableCellProperties,
  TableColumnResize,
  TableProperties,
  TableToolbar,
  Undo,
  List,
  Heading,
  Link,
  Indent,
  BlockQuote,
  MediaEmbed,
  Image,
  ImageUpload,
  PictureEditing,
  CKBox,
  CKBoxImageEdit,
  CloudServices,
  ImageResize,
  ImageToolbar,
  ImageCaption,
  ImageTextAlternative,
  ImageInsert,
  ImageStyle
} from 'ckeditor5';
import 'ckeditor5/ckeditor5.css';
import KonvaButtonPlugin from './ckeditor-plugins/KonvaButtonPlugin';
import {
  CkEditorContainer,
  CkEditorInnerContainer,
  CkEditorWrapper,
  MainContainer,
  SaveButton
} from './styles';
import api_app from '../../apis/api_app';

export default function CKEditorComponent({ id }) {
  const editorContainerRef = useRef(null);
  const editorRef = useRef(null);

  const [isLayoutReady, setIsLayoutReady] = useState(false);
  const [editorData, setEditorData] = useState('');
  const [buttonVisible, setButtonVisible] = useState(true);
  const [message, setMessage] = useState('');
  const [showKonva, setShowKonva] = useState(false)

  useEffect(() => {
    setIsLayoutReady(true);

    return () => setIsLayoutReady(false);
  }, []);

  const handleSubmit = async () => {
    try {
      const dataToSend = {
        agenda_id: id, 
        parecer: editorData,
      };

      await api_app.postProntuario(dataToSend);

      setMessage('Prontuário salvo com sucesso!');
      setButtonVisible(false);

    } catch (error) {
      setMessage('Erro ao salvar o Prontuário. Tente novamente.');
      console.error('Erro ao salvar o Prontuario', error);
    }
  };
  
  const handleEditorChange = (_, editor) => {
    const data = editor.getData();
    setEditorData(data);
  };

  const editorConfig = {
    toolbar: [
      'heading', '|',
      'bold',
      'italic',
      'link', '|',
      'bulletedList',
      'numberedList',
      'indent',
      'outdent', '|',
      'imageUpload',
      'konvaButton', '|',
      'blockQuote',
      'insertTable',
      'mediaEmbed',
      'undo',
      'redo'
    ],
    plugins: [
      Heading,
      Bold,
      Italic,
      Link,
      List,
      Indent,
      Image,
      ImageResize,
      ImageToolbar,
      ImageCaption,
      ImageTextAlternative,
      ImageInsert,
      PictureEditing,
      ImageUpload,
      KonvaButtonPlugin,
      CloudServices,
      CKBoxImageEdit,
      CKBox,
      BlockQuote,
      Table,
      TableCaption,
      TableCellProperties,
      TableColumnResize,
      TableProperties,
      TableToolbar,
      MediaEmbed,
      Undo,
      ImageStyle
    ],
    image: {
      toolbar: [
        'imageStyle:alignLeft',
        'imageStyle:alignCenter',
        'imageStyle:alignRight',
        '|',
        'imageTextAlternative'
      ],
      styles: [
        'full', 'side'
      ],
      resizeUnit: '%',
      resizeOptions: [
        {
          name: 'resizeImage:original',
          value: null,
          icon: 'original'
        },
        {
          name: 'resizeImage:50',
          value: '50',
          icon: 'medium'
        },
        {
          name: 'resizeImage:75',
          value: '75',
          icon: 'large'
        }
      ]
    },
    konvaButtonHandler: () => setShowKonva(true),
    initialData: "",
    language: 'pt',
    placeholder: 'Escreva ou cole seu conteúdo aqui!',
    table: {
      contentToolbar: [
        'tableColumn',
        'tableRow',
        'mergeTableCells',
        'tableProperties',
        'tableCellProperties'
      ]
    },
    ckbox: {
      tokenUrl: 'https://119750.cke-cs.com/token/dev/af36f2056c35821bb3fb22bad90ca8d2f9efef03605e0c65aa671b30daf0?limit=10',
      defaultUploadCategories: {
        Bitmaps: ['*bmp'],
        Pictures: ['*jpg', '*jpeg'],
        Scans: ['*png', '*tiff']
      },
    }
  };

  const handleEditorReady = (editor) => {
    editorRef.current = editor;
    const editingView = editor.editing.view
    if (editingView) {
      editingView.change((writer) => {
        writer.setStyle(
          "height",
          "500px",
          editingView.document.getRoot()
        );
      });
    }
  };

  const createPngFromDataUri = async (dataUri) => {
    const file = await fetch(dataUri)
      .then(res => res.blob())
      .then(blob => new File([blob], 'image.png', { type: 'image/png' }));
  
    return file;
  }

  const insertImageToCKEditor = (editorInstance, imageFile) => {
    const reader = new FileReader();
  
    reader.onload = function (event) {
      const imageDataUrl = event.target.result;
      editorInstance.model.change(writer => {
        const imageElement = writer.createElement('imageBlock', {
          src: imageDataUrl,
        });
        editorInstance.model.insertContent(imageElement, editorInstance.model.document.selection);
      });
    };
  
    reader.readAsDataURL(imageFile);
  }

  const addEditedImage = (imageDataUri) => {
    createPngFromDataUri(imageDataUri).then(imageFile => {
      const editorInstance = editorRef.current;
      if (editorInstance) {
        insertImageToCKEditor(editorInstance, imageFile);
      } else {
        console.error("CKEditor instance is not available.");
      }
    });
  };

  return (
      <MainContainer>
        <CkEditorContainer
          className="editor-container editor-container_classic-editor"
          ref={editorContainerRef}
        >
          <CkEditorInnerContainer>
            <CkEditorWrapper ref={editorRef}>
              {isLayoutReady &&
                <CKEditor
                  editor={ClassicEditor}
                  config={editorConfig}
                  onReady={handleEditorReady}
                  onChange={handleEditorChange}
                />
              }
            </CkEditorWrapper>
          </CkEditorInnerContainer>
        </CkEditorContainer>
        {buttonVisible && ( 
          <SaveButton
            className="text-white bg-green-500 hover:bg-green-600 focus:ring-4 border border-white font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 text-right"
            style={{ backgroundColor: '#a7eb51' }}
            onClick={handleSubmit}
          >
            Salvar
          </SaveButton>
        )}
        {message && (
            <p className={`mt-2 text-sm ${message.includes('Erro') ? 'text-red-600 dark:text-red-500' : 'text-green-600 dark:text-green-500'}`}>
              {message}
            </p>
        )}
        {showKonva &&
          <KonvaSketchModal
            show={showKonva}
            onBlurClick={setShowKonva}
            onCloseClick={setShowKonva}
            $overmodal={false}
            $fixedwidth={false}
          >
            <KonvaSketchBoard onCloseButton={setShowKonva} setImageUri={addEditedImage} />
          </KonvaSketchModal>
        }
      </MainContainer>
  );
}
