
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import 'flowbite';
import './index.css';
import { Layout } from './pages/layout';
import { Home } from './pages/home';
import { Perfil } from './pages/dadospessoais/meuperfil/dadopessoal';
import CreateEndereco from './pages/dadospessoais/meuperfil/dadopessoal/endereco/CreateEndereco';
import { DadosPacientes } from './pages/dadospessoais/meuperfil/dadospacientes';
import { DadosProfissionais } from './pages/dadospessoais/meuperfil/dadosprofissionais';
import { DadosFinanceiros } from './pages/dadospessoais/meuperfil/dadosfinanceiros';
import { Planos } from './pages/dadospessoais/meuperfil/planossec24';
import Agenda from './pages/dadospessoais/agendamentoconsulta/Agenda';
import Prontuario from './pages/dadospessoais/meuprontuario/Prontuario';
import { Relatorio } from './pages/dadospessoais/relatorios/Relatorio';
import EditEndereco from './pages/dadospessoais/meuperfil/dadopessoal/endereco/EditEndereco';
import { NewPlanoParticular } from './pages/dadospessoais/meuperfil/dadospacientes/planospartuculares/CreatePlanoParticular';
import { CreatePlanoIndividual } from './pages/dadospessoais/meuperfil/planossec24/planoindividual/CreatePlanoIndividual';
import { EditPlanoIndividual } from './pages/dadospessoais/meuperfil/planossec24/planoindividual/EditPlanoIndividual';
import { CreatePlanoProfissional } from './pages/dadospessoais/meuperfil/planossec24/planoprofissional/CreatePlanoProfissional';
import CreateEspecialidade from './pages/dadospessoais/meuperfil/dadosprofissionais/especialidades/CreateEspecialidade';
import { CreateDadosBancarios } from './pages/dadospessoais/meuperfil/dadosfinanceiros/dadosbancarios/CreateDadosBancarios';
import { EditDadosBancarios } from './pages/dadospessoais/meuperfil/dadosfinanceiros/dadosbancarios/EditDadosBancarios';
import { CreateDadosDoPix } from './pages/dadospessoais/meuperfil/dadosfinanceiros/dadosdopix/Createdadospix';
import { EditDadosDoPix } from './pages/dadospessoais/meuperfil/dadosfinanceiros/dadosdopix/EditDadosPix';
import CreateTelefone from './pages/dadospessoais/meuperfil/dadopessoal/telefone/CreateTelefone';
import EditTelefone from './pages/dadospessoais/meuperfil/dadopessoal/telefone/EditTelefone';
import CreateEmail from './pages/dadospessoais/meuperfil/dadopessoal/email/CreateEmail';
import EditEmail from './pages/dadospessoais/meuperfil/dadopessoal/email/EditEmail';
import { EditPlanoParticular } from './pages/dadospessoais/meuperfil/dadospacientes/planospartuculares/EditPlanoParticular';
import { EditPlanoProfissional } from './pages/dadospessoais/meuperfil/planossec24/planoprofissional/EditPlanoProfissional';
import { EditDadoCartao } from './pages/dadospessoais/meuperfil/dadosfinanceiros/dadocartao/EditDadoCartao';
import { CreateDadoCartao } from './pages/dadospessoais/meuperfil/dadosfinanceiros/dadocartao/CreateDadoCartao';
import EditEspecialidade from './pages/dadospessoais/meuperfil/dadosprofissionais/especialidades/EditEspecialidade';
import { ProntoAtendimento } from './pages/dadospessoais/prontoatendimento/ProntoAtendimento';
import AgendamentoConsulta from './pages/dadospessoais/agendamentoconsulta/AgendamentoConsulta';
import AgendaProfissional from './pages/dadosprofissionais/AgendaProfissional';
import { AtendimentoConsulta } from './pages/dadosprofissionais/AtendimentoConsulta';
import Register from './pages/register';
import api_app from "./apis/api_app";
import { createUser, setEmailPrincipal } from "./redux/user/slice";
import { store } from "./redux/store";
import { useKeycloak } from "@react-keycloak/web";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import SpinnerCustom from "./components/SpinnerCustom";
import { MeusPacientes } from './pages/dadosprofissionais/MeusPacientes';
import { DadosDoPaciente } from './pages/dadosprofissionais/DadosPaciente';

function App() {
  const { keycloak } = useKeycloak();
  let dispach = useDispatch();
  const [post, setPost] = useState(false);
  const [shouldRegister, setShouldRegister] = useState(false);
  const [shouldUpdateUser, setShouldUpdateUser] = useState(false);

  useEffect(() => {
    const pessoa = store.getState().user.user;
    if (pessoa?.id === undefined) {
      keycloak.onAuthSuccess = function () {
        api_app.findByPessoa(keycloak?.idTokenParsed?.email).then(result => {
          if (result) {
            dispach(createUser(result));
            dispach(setEmailPrincipal(keycloak?.idTokenParsed?.email))
          } else {
            setShouldRegister(true)
          }
          setPost(true);
        });
      }
    } else {
      setPost(true);
    }

  }, []);

  useEffect(() => {
    if(shouldUpdateUser) {
      api_app.findByPessoa(keycloak?.idTokenParsed?.email).then(result => {
        if (result) {
          dispach(createUser(result));
        } 
      });
      setShouldUpdateUser(false);
    }    
  }, [shouldUpdateUser])

  const ProtectedRoute = ({ children }) => {
    if (!keycloak.authenticated) {
      return null;
    }
    return shouldRegister ? <Navigate to="/registro" /> : children;
  };

  if (!post) {
    return (
      <div
        className="flex justify-center items-center h-screen">
        <SpinnerCustom
        />
      </div>
    );
  };

  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout nav={false} />}>
            <Route index element={
              <ProtectedRoute>
                <Home />
              </ProtectedRoute>
            } />

            <Route path="/perfil" element={
              <ProtectedRoute>
                <Perfil />
              </ProtectedRoute>
            }
            />

            <Route
              path="/adicionar-endereco"
              element={
                <ProtectedRoute>
                  <CreateEndereco updateStore={() => setShouldUpdateUser(true)}/>
                </ProtectedRoute>
              }
            />
            <Route
              path="/alterar-endereco/:id"
              element={
                <ProtectedRoute>
                  <EditEndereco />
                </ProtectedRoute>
              }
            />

            <Route
              path="/adicionar-telefone"
              element={
                <ProtectedRoute>
                  <CreateTelefone updateStore={() => setShouldUpdateUser(true)}/>
                </ProtectedRoute>
              }
            />
            <Route
              path="/alterar-telefone/:id"
              element={
                <ProtectedRoute>
                  <EditTelefone updateStore={() => setShouldUpdateUser(true)}/>
                </ProtectedRoute>
              }
            />

            <Route
              path="/adicionar-email"
              element={
                <ProtectedRoute>
                  <CreateEmail updateStore={() => setShouldUpdateUser(true)}/>
                </ProtectedRoute>
              }
            />
            <Route
              path="/alterar-email/:id"
              element={
                <ProtectedRoute>
                  <EditEmail updateStore={() => setShouldUpdateUser(true)}/>
                </ProtectedRoute>
              }
            />

            <Route
              path="/dados-pacientes"
              element={
                <ProtectedRoute>
                  <DadosPacientes />
                </ProtectedRoute>
              }
            />

            <Route
              path="/adicionar-plano"
              element={
                <ProtectedRoute>
                  <NewPlanoParticular />
                </ProtectedRoute>
              }
            />
            <Route
              path="/alterar-plano"
              element={
                <ProtectedRoute>
                  <EditPlanoParticular />
                </ProtectedRoute>
              }
            />

            <Route
              path="/dados-profissionais"
              element={
                <ProtectedRoute>
                  <DadosProfissionais />
                </ProtectedRoute>
              }
            />

            <Route
              path="/adicionar-especialidade"
              element={
                <ProtectedRoute>
                  <CreateEspecialidade />
                </ProtectedRoute>
              }
            />
            <Route
              path="/alterar-especialidade/:id"
              element={
                <ProtectedRoute>
                  <EditEspecialidade />
                </ProtectedRoute>
              }
            />

            <Route
              path="/dados-financeiros"
              element={
                <ProtectedRoute>
                  <DadosFinanceiros />
                </ProtectedRoute>
              }
            />

            <Route
              path="/adicionar-dados-bancarios"
              element={
                <ProtectedRoute>
                  <CreateDadosBancarios />
                </ProtectedRoute>
              }
            />
            <Route
              path="/alterar-dados-bancarios"
              element={
                <ProtectedRoute>
                  <EditDadosBancarios />
                </ProtectedRoute>
              }
            />

            <Route
              path="/adicionar-dados-do-pix"
              element={
                <ProtectedRoute>
                  <CreateDadosDoPix />
                </ProtectedRoute>
              }
            />
            <Route
              path="/alterar-dados-do-pix"
              element={
                <ProtectedRoute>
                  <EditDadosDoPix />
                </ProtectedRoute>
              }
            />

            <Route
              path="/adicionar-dado-cartao"
              element={
                <ProtectedRoute>
                  <CreateDadoCartao />
                </ProtectedRoute>
              }
            />
            <Route
              path="/alterar-dado-cartao"
              element={
                <ProtectedRoute>
                  <EditDadoCartao />
                </ProtectedRoute>
              }
            />

            <Route
              path="/planos"
              element={
                <ProtectedRoute>
                  <Planos />
                </ProtectedRoute>
              }
            />

            <Route
              path="/adicionar-plano-individual"
              element={
                <ProtectedRoute>
                  <CreatePlanoIndividual />
                </ProtectedRoute>
              }
            />

            <Route
              path="/alterar-plano-individual"
              element={
                <ProtectedRoute>
                  <EditPlanoIndividual />
                </ProtectedRoute>

              }
            />

            <Route
              path="/adicionar-plano-profissional"
              element={
                <ProtectedRoute>
                  <CreatePlanoProfissional />
                </ProtectedRoute>
              }
            />

            <Route
              path="/alterar-plano-profissional"
              element={
                <ProtectedRoute>
                  <EditPlanoProfissional />
                </ProtectedRoute>
              }
            />

            <Route
              path="/agendamento-de-consulta"
              element={
                <ProtectedRoute>
                  <Agenda />
                </ProtectedRoute>
              }
            />
            <Route
              path="/pronto-atendimento"
              element={
                <ProtectedRoute>
                  <ProntoAtendimento />
                </ProtectedRoute>
              }
            />
            <Route
              path="/prontuario"
              element={
                <ProtectedRoute>
                  <Prontuario />
                </ProtectedRoute>
              }
            />
            <Route
              path="/relatorio"
              element={
                <ProtectedRoute>
                  <Relatorio />
                </ProtectedRoute>
              }
            />

            <Route
              path='/agendamento-de-consulta/agendar-consulta/:id'
              element={
                <ProtectedRoute>
                  <AgendamentoConsulta />
                </ProtectedRoute>
              }
            />

            <Route
              path='/dados-profissionais/agenda'
              element={
                <ProtectedRoute>
                  <AgendaProfissional />
                </ProtectedRoute>
              }
            />
            <Route
              path='/dados-profissionais/agenda/atendimento-consulta/:id'
              element={
                <ProtectedRoute>
                  <AtendimentoConsulta />
                </ProtectedRoute>
              }
            />

            <Route
              path='/dados-profissionais/meus-pacientes'
              element={
                <ProtectedRoute>
                  <MeusPacientes />
                </ProtectedRoute>
              }
            />
            <Route
              path='/dados-profissionais/meus-pacientes/dados-do-paciente/:id'
              element={
                <ProtectedRoute>
                  <DadosDoPaciente />
                </ProtectedRoute>
              }
            />
          </Route>

          <Route path="/registro" element={<Register setShouldRegister={setShouldRegister} />} />

          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </BrowserRouter>
    </div>
  )
}

export default App