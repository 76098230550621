import React from "react";
import { KonvaButton, KonvaIcon } from "../konvaSketchBoard/styles";

const KonvaPenButton = ({ setTool, tool }) => {
    return (
        <KonvaButton onClick={setTool}>
            <KonvaIcon
                size={15}
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path fill={tool !== "pen" ? "#0000007e" : "#000000"} d="M11.15 3.57l4.91 4.91-10.65 10.65-4.37.48C.43 19.01-.06 18.52.01 17.96l.48-4.37 10.65-10.65zm7.94-.73l-2.3-2.3c-.72-.72-1.88-.72-2.6 0l-2.17 2.17 4.91 4.91 2.17-2.17c.72-.72.72-1.88 0-2.6z"></path>
            </KonvaIcon>
        </KonvaButton>
    )
}

export default KonvaPenButton