import styled from 'styled-components';

export const KonvaContainer = styled.div`
  align-items: center;
  background-color: #47434617;
  backdrop-filter: blur(10px);
  display: flex;
  height: 100vh;
  justify-content: center;
  position: fixed;
  width: 100vw;
  flex-wrap: wrap;
  z-index: 999;
  left: 0;
  top: 0;
  max-width: 100vw;
  
  ${({ $overmodal }) =>
    $overmodal && `
        background-color: #00000040;
        backdrop-filter: none;
    `}
`;

export const KonvaModal = styled.div`
  display: flex;
  background: white;
  flex-direction: column;
  align-items: center;
  border-radius: 1.5rem;
  margin: 15px;
  padding: 15px;
  z-index: 1;
  max-width: 90vw;
  max-height: 80vh;
`;

export const KonvaModalInner = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding: 0 15px;
  box-sizing: content-box;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #555;
    cursor: pointer;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
  }
  
  ${({ $fixedwidth }) =>
    $fixedwidth && `
      width: fit-content;
    `}
`;

export const KonvaClose = styled.button`
  padding: 2.4px;
  border-radius: 7px;
  height: 20.8px;
  width: 20.8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: transparent;
  transition: color 200ms;
  margin-right: 0;
  margin-left: auto;
  margin-bottom: 10px;

  &:hover {
    background-color: #8f96a31a;
  }
`;

export const KonvaCloseIcon = styled.svg`
  cursor: pointer;
  height: 16px;
  min-width: 16px;
`;

export const KonvaBlur = styled.div`
  height: 100vh;
  width: 100vw;
  cursor: pointer;
  position: absolute;
  overflow: hidden;
`;