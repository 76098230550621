import React, { useState } from "react";
import { TittleCustom } from "../../../../components/TittleCustom";
import { ButtonSaveCustom } from "../../../../components/ButtonSaveCustom";
import InputCustom from "../../../../components/InputCustom";
import { TextAreaCustom } from "../../../../components/TextAreaCustom";
import { pessoa_fisicaModel, pessoa_profissionalModel, pessoaModel } from "../../../../resource/request";
import Notification from "../../../../components/notification/notification";
import { store } from "../../../../redux/store";
import api_app from "../../../../apis/api_app";
import withRouter from "../../../../helpers/withRouter";
import FormFiles from "../dadopessoal/FormFiles";

const FormProfissional: React.FC<any> = (props) => {
    const [pessoaProfissional, setPessoaProfissional] = useState<pessoa_profissionalModel>(
        store.getState().user?.user.pessoa_profissional.find(() => true) || ({} as pessoa_profissionalModel));
    const [pessoaFisica, setPessoaFisica] = useState<pessoa_fisicaModel>(
        store.getState().user?.user.pessoa_fisica.find(() => true) || ({} as pessoa_fisicaModel)
    );
    const [msg, setMsg] = useState<string>('');
    const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
    const [isSubmittedResumo, setIsSubmittedResumo] = useState<boolean>(false);

    const showNotification = (action:any) => {
        action(true);
            setTimeout(() => {
                action(false);
              }, 5000); 
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = event.target;
        setPessoaFisica((prevPessoaFisica) => ({
            ...prevPessoaFisica,
            [name]: value,
        }));
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        try {
            await api_app.atualizarPessoaFisica(pessoaFisica.id, pessoaFisica);
            setMsg("Seus dados foram atualizados com sucesso!");
            setIsSubmitted(true);
            props.router.updateStore();
        } catch (error) {
            console.error("Erro ao atualizar os dados:", error);
        }
    };

    const handleChangeResumo = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const { name, value } = event.target;
        setPessoaProfissional((prevPessoaProfissional) => ({
            ...prevPessoaProfissional,
            resumo_curriculo: value,
        }));
    };
    const handleSubmitResumo = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        try {
            await api_app.atualizarPessoaProfissional(pessoaProfissional.id, pessoaProfissional);            
            showNotification(setIsSubmittedResumo);
            props.router.updateStore();
        } catch (error) {
            console.error("Erro ao atualizar os dados:", error);
        }
    };

    return (
        <>
            <form onSubmit={handleSubmitResumo}>
                <div className="mt-6">
                    <TextAreaCustom
                        label="Meu Curriculo:"
                        value={pessoaProfissional.resumo_curriculo || ''}
                        name="resumo_curriculo"
                        onChange={handleChangeResumo}
                    />
                    <div className="mt-4">
                     <ButtonSaveCustom type="submit" name="Salvar" />
                    </div>
                    {isSubmittedResumo && <Notification mode="success" message={"Seus dados foram atualizados com sucesso!"} />}
                </div>
            </form>           
            <form onSubmit={handleSubmit}>
                <div className="text-lg font-semibold text-gray-900 dark:text-white">
                    <TittleCustom name="Redes Sociais" />
                </div>
                <div className="grid gap-4 mb-4">
                    <InputCustom
                        label="Site:"
                        value={pessoaFisica.site || ''}
                        type="text"
                        name="site"
                        placeholder="Site"
                        onChange={handleChange}
                    />
                    <InputCustom
                        label="Facebook:"
                        value={pessoaFisica.facebook || ''}
                        type="text"
                        name="facebook"
                        placeholder="Facebook"
                        onChange={handleChange}
                    />
                    <InputCustom
                        label="Instagram:"
                        value={pessoaFisica.instagram || ''}
                        type="text"
                        name="instagram"
                        placeholder="Instagram"
                        onChange={handleChange}
                    />
                    <InputCustom
                        label="Lattes:"
                        value={pessoaFisica.lattes || ''}
                        type="text"
                        name="lattes"
                        placeholder="Lattes"
                        onChange={handleChange}
                    />
                    <InputCustom
                        label="X:"
                        value={pessoaFisica.twitter || ''}
                        type="text"
                        name="twitter"
                        placeholder="X"
                        onChange={handleChange}
                    />
                    <InputCustom
                        label="Linkedin:"
                        value={pessoaFisica.linkedin || ''}
                        type="text"
                        name="linkedin"
                        placeholder="Linkedin"
                        onChange={handleChange}
                    />
                    <InputCustom
                        label="TikTok:"
                        value={pessoaFisica.tiktok || ''}
                        type="text"
                        name="tiktok"
                        placeholder="TikTok"
                        onChange={handleChange}
                    />
                </div>

                <div className="flex items-center mt-6 space-x-4 rtl:space-x-reverse">
                    {!isSubmitted && (
                        <ButtonSaveCustom type="submit" name="Salvar" />
                    )}
                </div>
                <p className="mt-2 text-sm text-green-600 dark:text-green-500">{msg}</p>
            </form>

            <div>
                <FormFiles usuario_id={2} />
            </div>
        </>
    );
};

export default withRouter(FormProfissional);
