import { Component } from "react";
import { TittleCustom } from "../../../../../components/TittleCustom";
import { ButtonReturnCustom } from "../../../../../components/ButtonReturnCustom";
import { ButtonSaveCustom } from "../../../../../components/ButtonSaveCustom";
import InputCustom from "../../../../../components/InputCustom";
import SelectCustom, { Option } from "../../../../../components/SelectCustom";

interface State {
    instituicao_financeira: Option[];
}

export class EditDadoCartao extends Component {
    state: State = {
        instituicao_financeira: [{ key: "1", value: "Carregando...", disabled: true }],
    }

    render() {
        return <>
            <form>
                <div className="flex items-center justify-between rounded-t dark:border-gray-600">
                    <TittleCustom
                        name="Alterar Dados de Cartões"
                    />
                    <ButtonReturnCustom
                        link="/dados-financeiros"
                    />
                </div>
                <div className="grid gap-6 mb-6 md:grid-cols-2">
                    <SelectCustom
                        label="Instituição Financeira:"
                        name="instituicao_financeira"
                        defaultValue="Selecione"
                        options={this.state.instituicao_financeira}
                    />
                    <InputCustom
                        label="Número do Cartão:"
                        value=""
                        type="number"
                        name="name"
                        placeholder="Número do Cartão"
                    />
                    <InputCustom
                        label="Validade:"
                        value=""
                        type="number"
                        name="name"
                        placeholder="Validade"
                    />
                    <InputCustom
                        label="Nome Do Titular:"
                        value=""
                        type="text"
                        name="name"
                        placeholder="Nome Do Titular"
                    />
                </div>
                <div>
                    <InputCustom
                        label="Código De Segurança:"
                        value=""
                        type="number"
                        name="name"
                        placeholder="Código De Segurança"
                    />
                </div>
                <div className="flex items-center mt-6 space-x-4 rtl:space-x-reverse">
                    <ButtonSaveCustom
                        type="submit"
                        name="Salvar"
                    />
                </div>
            </form>
        </>
    }
}
