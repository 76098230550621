import { Tabs } from "flowbite-react";
import { HiOutlineDocumentText, HiClipboardList, HiUserCircle, HiOutlineUserCircle } from "react-icons/hi";
import React, { useEffect, useState } from "react";
import { JitsiMeeting } from "@jitsi/react-sdk";
import { store } from "../../redux/store";
import { useParams } from "react-router-dom";
import api_app from "../../apis/api_app";
import { agendaModel } from "../../resource/request";
import { TittleCustom } from "../../components/TittleCustom";
import InputDisabled from "../../components/InputDisabled";
import { TextAreaCustom } from "../../components/TextAreaCustom";
import { useNavigate } from 'react-router-dom';
import SpinnerCustom from "../../components/SpinnerCustom";
import { Accordion, Timeline } from "flowbite-react";
import { extractTimeFromDateString, parseDateToDMYFormat } from "../../helpers/date_helpers";
import CKEditorComponent from "../../components/CKEditorComponent/CKEditorComponent";
import { ProntuarioBox } from "./styles";
import MemedTab from "../../components/memedTabEmbed/memedTab";
import { v4 as uuidv4 } from 'uuid';

export function AtendimentoConsulta() {
  const [isPatient, setIsPatient] = useState(false);
  const [pessoaNome, setPessoaNome] = useState(store.getState().user?.user?.nome);
  const [image, setImage] = useState<any>(null);
  let params = useParams();
  const id = params.id;
  const [agenda, setAgenda] = useState({} as agendaModel);
  const [updateAgenda, setUpdateAgenda] = useState({} as agendaModel);
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [rating, setRating] = useState(0);
  const [isModalRemarcacaoOpen, setIsModalRemarcacaoOpen] = useState(false);
  const [selectedDays, setSelectedDays] = useState(10);
  const [editorContent, setEditorContent] = useState('');
  const cremersLink = 'https://servicos.cremers.org.br/crvirtual-espacomedico/#/login';
  const memedLink = 'https://memed.com.br/';
  const updatedUuidConsulta = updateAgenda.uuid_consulta || agenda.uuid_consulta;

  useEffect(() => {
    api_app.getAgendaById(id).then((r) => {

      if (![3, 4].includes(r.data.status_agenda_id)) {
        navigate('/prontuario');
      } else if (!r.data.uuid_consulta) {
        const uuidConsulta = uuidv4();
        setUpdateAgenda({
          ...updateAgenda,
          uuid_consulta: uuidConsulta
        })
      }
      if (![r.data.pessoa_agenda_paciente_idTopessoa?.id, r.data.pessoa?.id].includes(store.getState().user?.user?.id)) {
        navigate('/prontuario');
      }

      setAgenda(r.data);
      const documentos = r.data.pessoa_agenda_paciente_idTopessoa?.pessoa_documento?.filter((x: any) => x.tipo_documento_id == 7);
      if (documentos?.length > 0) {
        const primeiraImagem = documentos[0];
        setImage(primeiraImagem);
      } else {
        setImage("/FotoUser.jpg");
      }
      if (store.getState().user?.user?.id === r.data.pessoa_agenda_paciente_idTopessoa?.id) {
        setIsPatient(true);
      }
    });

  }, [id]);

  const handleTabChange = (activeTab: number) => {
    switch (activeTab) {
      case 1:
        break;
      case 2:
        break;
      case 3:
        window.open(cremersLink);
        break;
      case 4:
        window.open(memedLink);
        break;
      case 5:
        break;
      default:
        break;
    }
  }

  const handleRatingSubmit = (event: any) => {
    event.preventDefault();
    const commonUpdates = {
      status_agenda_id: 5,
      dat_atualizado_em: new Date(),
    };

    if (isPatient) {
      setUpdateAgenda({
        ...updateAgenda,
        ...commonUpdates,
        avaliacao_paciente: rating,
      });
    } else {
      setUpdateAgenda({
        ...updateAgenda,
        ...commonUpdates,
        avaliacao_profissional: rating,
      });
    }
  };

  function AtualizaAgenda() {
    api_app.patchAgenda(id, updateAgenda).then(x => {
      if (updateAgenda.status_agenda_id === 5) {
        if (isPatient) {
          navigate('/prontuario');
        }
        else {
          navigate('/dados-profissionais/agenda');
        }
      }
    });
  }

  function updateAgendaClose() {
    const commonUpdates = {
      status_agenda_id: 5,
      dat_atualizado_em: new Date(),
    };
    if (isPatient) {
      setUpdateAgenda({
        ...updateAgenda,
        ...commonUpdates,
        dat_fim_consulta_pac: new Date(),
      });
    } else {
      setUpdateAgenda({
        ...updateAgenda,
        ...commonUpdates,
        dat_fim_consulta_pro: new Date(),
      });
    }
  }

  function updateAgendaStart() {
    const commonUpdates = {
      status_agenda_id: 4,
      dat_atualizado_em: new Date(),
    };

    if (isPatient) {
      setUpdateAgenda({
        ...updateAgenda,
        ...commonUpdates,
        dat_inicio_consulta_pac: new Date(),
      });
    } else {
      setUpdateAgenda({
        ...updateAgenda,
        ...commonUpdates,
        dat_inicio_consulta_pro: new Date(),
      });
    }
  }

  useEffect(() => {
    if (updateAgenda.status_agenda_id === 4 ||
      updateAgenda.status_agenda_id === 5 ||
      updateAgenda.avaliacao_profissional !== agenda.avaliacao_profissional ||
      updateAgenda.avaliacao_paciente !== agenda.avaliacao_paciente
    ) {
      AtualizaAgenda();
    }
  }, [updateAgenda]);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openModalRemarcacao = () => setIsModalRemarcacaoOpen(true);

  const closeModalRemarcacao = () => setIsModalRemarcacaoOpen(false);

  const handleDaysChange = (event: any) => {
    setSelectedDays(parseInt(event.target.value));
  };

  const handleConfirm = () => {
    // Adicione a lógica para processar o reagendamento aqui
    closeModalRemarcacao();
  };

  const handleTabClick = (url: any) => {
    window.open(url, '_blank');
  };

  const handleRatingChange = (num: any) => {
    setRating(num);
  };

  if (agenda.id === undefined || agenda.id === 0) {
    return <>
      <div className="flex justify-center items-center h-screen bg-gray-100 dark:bg-gray-900">
        <SpinnerCustom />
      </div>
    </>
  }

  return (
    <>
      <div className="mb-1" style={{ textAlign: "center" }}>
        {updatedUuidConsulta && <JitsiMeeting
          domain={"video.saudeemcasa24horas.com.br"}
          roomName={`consulta-saude-em-casa-24h-${updatedUuidConsulta}`}
          getIFrameRef={iframeRef => { iframeRef.style.height = '450px'; }}
          configOverwrite={{
            startWithVideoMuted: false,
            startWithAudioMuted: false,
            prejoinPageEnabled: false,
            disableModeratorIndicator: true,
            startScreenSharing: false,
            enableEmailInStats: false,
          }}
          interfaceConfigOverwrite={{
            JITSI_LOGO_REACT: '/UserLogo.jpeg',
          }}
          userInfo={{
            displayName: pessoaNome || "Default Name",
            email: store.getState().user.user.pessoa_email?.find((x) => true)?.email || ''
          }}
          onApiReady={(externalApi) => {
            externalApi.addListener('readyToClose', () => {
              updateAgendaClose();
            })
            externalApi.addListener('videoConferenceJoined', () => {
              updateAgendaStart();
            })
          }}
        />}
      </div>

      <div className="flex justify-end gap-2">

        <form onSubmit={(e) => {
          e.preventDefault();
          openModalRemarcacao();
        }}>
          <button
            type="submit"
            className="text-white bg-blue-800 hover:bg-blue-900 focus:ring-4 font-medium rounded-lg text-sm px-5 py-2.5"
            style={{ backgroundColor: "#598c89" }}
          >
            Reagendar a consulta
          </button>
        </form>

        <form onSubmit={(e) => { e.preventDefault(); openModal(); }}>
          <button
            type="submit"
            className="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900"
          >
            Encerrar a Consulta
          </button>
        </form>
      </div>

      {!isPatient && (
        <div className="overflow-x-auto">
          <Tabs aria-label="Full width tabs" onActiveTabChange={handleTabChange}>

            <Tabs.Item disabled title={agenda.pessoa_agenda_paciente_idTopessoa?.nome || ''} icon={HiUserCircle} />

            <Tabs.Item active title="Dados do Paciente" icon={HiOutlineUserCircle}>
              <Tabs>
                <Tabs.Item title="Informações Gerais">
                  <div>
                    <form>
                      <div className="flex justify-between items-center text-lg">
                        <TittleCustom name="Dados Pessoais" />
                      </div>
                      <div className="flex flex-row justify-start items-center mb-6">
                        <div className="flex-shrink-0 w-1/2 items-center">
                          <div className="flex-shrink-0 ml-28 w-1/2 ">
                            {image && image.id && (
                              <img
                                alt="Document"
                                src={`${process.env.REACT_APP_BASE_URL}/pessoa-documento/documento/${image.id}`}
                                className="max-w-full max-h-full"
                              />
                            )}
                          </div>
                        </div>
                        <div className="flex-1 ml-1 flex flex-col items-center mt-4">
                          <div className="mb-2 w-full">
                            <InputDisabled
                              label="Nome Completo:"
                              value={agenda.pessoa_agenda_paciente_idTopessoa?.nome || ''}
                              type="text"
                              name="nome"
                              placeholder="Nome Completo"
                            />
                          </div>
                          <div className="mb-2 w-full">
                            <InputDisabled
                              label="Nome Social:"
                              value={agenda.pessoa_agenda_paciente_idTopessoa?.pessoa_fisica?.find((x) => true)?.nome_social || ''}
                              type="text"
                              name="nome_social"
                              placeholder="Nome Social"
                            />
                          </div>
                          <div className="mb-2 w-full">
                            <InputDisabled
                              label="CPF:"
                              value={agenda.pessoa_agenda_paciente_idTopessoa?.cpf_cnpj || ''}
                              type="text"
                              name="cpf_cnpj"
                              placeholder="000.000.000-00"
                            />
                          </div>
                          <div className="mb-2 w-full">
                            <InputDisabled
                              label="Data de Nascimento:"
                              value={agenda.pessoa_agenda_paciente_idTopessoa?.dat_nascimento_fundacao
                                ? parseDateToDMYFormat(agenda.pessoa_agenda_paciente_idTopessoa.dat_nascimento_fundacao) : ""}
                              type="text"
                              name="data_nascimento"
                              placeholder="00/00/0000"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="grid gap-6 mb-6 md:grid-cols-2">
                        <InputDisabled
                          label="Telefone:"
                          value={agenda.pessoa_agenda_paciente_idTopessoa?.pessoa_telefone?.find((x) => x.flg_principal === 'S')?.telefone || ''}
                          type="text"
                          name="telefone"
                          placeholder="Telefone"
                        />
                        <InputDisabled
                          label="E-mail:"
                          value={agenda.pessoa_agenda_paciente_idTopessoa?.pessoa_email?.find((x) => x.flg_principal === 'S')?.email || ''}
                          type="text"
                          name="email"
                          placeholder="E-mail"
                        />
                        <InputDisabled
                          label="Estado Civil:"
                          value={agenda.pessoa_agenda_paciente_idTopessoa?.pessoa_fisica?.find((x) => true)?.estado_civil?.estado_civil.toString() || ''}
                          type="text"
                          name="estado_civil"
                          placeholder="Estado Civil"
                        />
                        <div className="mb-2 w-full">
                          <InputDisabled
                            label="RG/RNE:"
                            value={agenda.pessoa_agenda_paciente_idTopessoa?.pessoa_fisica?.find((x) => true)?.rg || ''}
                            type="text"
                            name="rg"
                            placeholder="RG/RNE"
                          />
                        </div>
                        <InputDisabled
                          label="Órgão Expedidor/UF:"
                          value={agenda.pessoa_agenda_paciente_idTopessoa?.pessoa_fisica?.find((x) => true)?.rg_orgao_expedidor || ''}
                          type="text"
                          name="rg_orgao_expedidor"
                          placeholder="Órgão Expedidor / UF"
                        />
                        <InputDisabled
                          label="Nº Do Cartão Do SUS:"
                          value={agenda.pessoa_agenda_paciente_idTopessoa?.pessoa_fisica?.find((x) => true)?.cartao_sus || ''}
                          type="text"
                          name="cartao_sus"
                          placeholder="Nº Do Cartão Do SUS"
                        />
                        <InputDisabled
                          label="Nome Da Mãe:"
                          value={agenda.pessoa_agenda_paciente_idTopessoa?.pessoa_fisica?.find((x) => true)?.nome_mae || ''}
                          type="text"
                          name="nome_mae"
                          placeholder="Nome Da Mãe"
                        />
                        <InputDisabled
                          label="Nome Do Pai:"
                          value={agenda.pessoa_agenda_paciente_idTopessoa?.pessoa_fisica?.find((x) => true)?.nome_pai || ''}
                          type="text"
                          name="nome_pai"
                          placeholder="Nome Do Pai"
                        />
                        <InputDisabled
                          label="Etnia:"
                          value={agenda.pessoa_agenda_paciente_idTopessoa?.pessoa_fisica?.find((x) => true)?.raca_etnia?.raca_etnia.toString() || ''}
                          name="raca_etnia"
                          type="text"
                          placeholder="Etnia"
                        />
                        <InputDisabled
                          label="Gênero:"
                          value={agenda.pessoa_agenda_paciente_idTopessoa?.pessoa_fisica?.find((x) => true)?.tipo_genero?.tipo_genero.toString() || ''}
                          name="tipo_genero"
                          type="text"
                          placeholder="Gênero"
                        />
                        <InputDisabled
                          label="Pronome Gênero:"
                          value={agenda.pessoa_agenda_paciente_idTopessoa?.pessoa_fisica?.find((x) => true)?.tipo_genero_pronome?.tipo_genero_pronome.toString() || ''}
                          name="tipo_genero_pronome"
                          type="text"
                          placeholder="Pronome Gênero"
                        />
                        <InputDisabled
                          label="Identidade De Gênero:"
                          value={agenda.pessoa_agenda_paciente_idTopessoa?.pessoa_fisica?.find((x) => true)?.tipo_identidade_genero?.tipo_identidade_genero.toString() || ''}
                          name="tipo_identidade_genero"
                          type="text"
                          placeholder="Identidade De Gênero"
                        />
                        <InputDisabled
                          label="Profissão:"
                          value={agenda.pessoa_agenda_paciente_idTopessoa?.pessoa_fisica?.find((x) => true)?.profissao || ''}
                          type="text"
                          name="profissao"
                          placeholder="Profissão"
                        />
                        <InputDisabled
                          label="Pis/Pasep:"
                          value={agenda.pessoa_agenda_paciente_idTopessoa?.pessoa_fisica?.find((x) => true)?.pis_pasep || ''}
                          type="text"
                          name="pis_pasep"
                          placeholder="Pis/Pasep"
                        />
                      </div>
                    </form>
                  </div>
                </Tabs.Item>

                <Tabs.Item title="Histórico Médico">
                  <div>
                    <form>
                      <div className="flex justify-between items-center text-lg mb-2">
                        <TittleCustom
                          name="Minha saúde"
                        />
                      </div>
                      <div className="grid gap-6 mb-6 md:grid-cols-2">
                        <h1>Fuma: {agenda.pessoa_agenda_paciente_idTopessoa?.pessoa_paciente?.find((x) => true)?.flg_tabagismo == 'S' ? 'Sim' : 'Não'}</h1>
                        <h1>Bebe: {agenda.pessoa_agenda_paciente_idTopessoa?.pessoa_paciente?.find((x) => true)?.flg_etilismo == 'S' ? 'Sim' : 'Não'}</h1>
                        <h1>Usou ou Usa Drogas: {agenda.pessoa_agenda_paciente_idTopessoa?.pessoa_paciente?.find((x) => true)?.flg_utilizou_drogas == 'S' ? 'Sim' : 'Não'}</h1>
                        <h1>Pratica Exercícios Físicos: {agenda.pessoa_agenda_paciente_idTopessoa?.pessoa_paciente?.find((x) => true)?.flg_pratica_exercicio == 'S' ? 'Sim' : 'Não'}</h1>
                      </div>
                      <div className="grid gap-4 mb-4">
                        <TextAreaCustom
                          label="Você possui alguma alergia?"
                          value={agenda.pessoa_agenda_paciente_idTopessoa?.pessoa_paciente?.find((x) => true)?.alergias || ''}
                          name='alergias'
                          disabled
                        />
                        <TextAreaCustom
                          label="Liste aqui as doenças e tratamentos que você tenha realizado anteriormente:"
                          value={agenda.pessoa_agenda_paciente_idTopessoa?.pessoa_paciente?.find((x) => true)?.doencas_previas || ''}
                          name='doencas_previas'
                          disabled
                        />
                        <TextAreaCustom
                          label="Informe aqui se estiver com alguma doença ou passando por algum tratamento:"
                          value={agenda.pessoa_agenda_paciente_idTopessoa?.pessoa_paciente?.find((x) => true)?.doencas_atuais || ''}
                          name='doencas_atuais'
                          disabled
                        />
                        <TextAreaCustom
                          label="Informe se e quais medicações de uso contínuo você faz uso:"
                          value={agenda.pessoa_agenda_paciente_idTopessoa?.pessoa_paciente?.find((x) => true)?.medicacoes_continuas || ''}
                          name='medicacoes_continuas'
                          disabled
                        />
                        <TextAreaCustom
                          label="Já fez alguma cirurgia? Qual? Em que ano?"
                          value={agenda.pessoa_agenda_paciente_idTopessoa?.pessoa_paciente?.find((x) => true)?.cirurgias || ''}
                          name='cirurgias'
                          disabled
                        />
                      </div>
                    </form>
                  </div>
                </Tabs.Item>

                <Tabs.Item title="Histórico de Prontuário">
                  <Accordion>
                    {agenda.pessoa_agenda_paciente_idTopessoa?.agenda_agenda_paciente_idTopessoa?.filter((x) => x.status_agenda_id === 5).map((agenda, index) => (

                      <Accordion.Panel key={index}>

                        <Accordion.Title className="bg-gray-200 p-4 rounded-lg shadow-md">
                          <div style={{ display: 'flex', justifyContent: 'space-between' }}>                            
                            <p style={{ marginRight: '10px' }}>
                              {agenda.data
                                  ? `${parseDateToDMYFormat(new Date(agenda.data))} - ${extractTimeFromDateString(new Date(agenda.data))} - `
                                  : '- - - '
                              }
                            </p>
                            <p>
                              Médico: {`${agenda.pessoa?.nome || '-'} - `}
                              {agenda.especialidade?.especialidade || '-'}
                            </p>
                          </div>

                        </Accordion.Title>
                        <Accordion.Content className="p-4">
                          <Timeline className="mt-4">
                            <Timeline.Item>
                              <Timeline.Content>
                                <Timeline.Body>
                                  <div className="ml-2">
                                    <ProntuarioBox
                                      dangerouslySetInnerHTML={{
                                        __html: `${agenda.prontuario?.find((x) => x)?.parecer ||
                                          ''
                                          }<br/><br/>${editorContent}`,
                                      }}
                                    />
                                  </div>
                                </Timeline.Body>
                              </Timeline.Content>
                            </Timeline.Item>
                          </Timeline>
                        </Accordion.Content>
                      </Accordion.Panel>
                    )) ?? []}
                  </Accordion>
                </Tabs.Item>

              </Tabs>
            </Tabs.Item>

            <Tabs.Item title="Prontuário" icon={HiOutlineDocumentText}>
              <div>
                <CKEditorComponent
                  id={agenda.id}
                />
              </div>
              <Accordion>
                {agenda.pessoa_agenda_paciente_idTopessoa?.agenda_agenda_paciente_idTopessoa?.filter((x) => x.status_agenda_id === 5).map((agenda, index) => (
                  <Accordion.Panel key={index}>
                    <Accordion.Title className="bg-gray-200 p-4 rounded-lg shadow-md">
                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <p style={{ marginRight: '10px' }}>
                          {agenda.data
                              ? `${parseDateToDMYFormat(new Date(agenda.data))} - ${extractTimeFromDateString(new Date(agenda.data))} - `
                              : '- - - '
                          }
                        </p>
                        <p>
                          Médico: {`${agenda.pessoa?.nome || '-'} - `}
                          {agenda.especialidade?.especialidade || '-'}
                        </p>
                      </div>
                    </Accordion.Title>
                    <Accordion.Content className="p-4">
                      <Timeline className="mt-4">
                        <Timeline.Item>
                          <Timeline.Content>
                            <Timeline.Body>
                              <div className="ml-2">
                                <ProntuarioBox
                                  dangerouslySetInnerHTML={{
                                    __html: `${agenda.prontuario?.find((x) => x)?.parecer ||
                                      ''
                                      }<br/><br/>${editorContent}`,
                                  }}
                                />
                              </div>
                            </Timeline.Body>
                          </Timeline.Content>
                        </Timeline.Item>
                      </Timeline>
                    </Accordion.Content>
                  </Accordion.Panel>
                )) ?? []}
              </Accordion>
            </Tabs.Item>

            <Tabs.Item title="Receita (CREMERS)" icon={HiClipboardList}>
              Você foi redirecionado. Por favor, continue o fluxo na nova aba aberta.
            </Tabs.Item>

            <Tabs.Item title="Receita (MEMED)" icon={HiClipboardList}>
              Você foi redirecionado. Por favor, continue o fluxo na nova aba aberta.
            </Tabs.Item>
            <Tabs.Item title="Integração MEMED" icon={HiClipboardList}>
              <MemedTab paciente={agenda.pessoa_agenda_paciente_idTopessoa} agenda={agenda} />
            </Tabs.Item>
          </Tabs>
        </div>
      )}

      {isModalOpen && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-50 dark:bg-gray-900 dark:bg-opacity-80">
          <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-8 w-auto mx-auto">
            <h2 className="text-2xl font-bold mb-4 text-center text-gray-900 dark:text-gray-200">Consulta Finalizada</h2>
            <form onSubmit={handleRatingSubmit}>
              <div className="mb-6">
                <label htmlFor="rating" className="block text-gray-700 dark:text-gray-300 text-sm font-medium mb-2">
                  Como você avalia sua experiência com a consulta de hoje? Avalie de 1 a 10.
                </label>
                <div className="flex flex-wrap justify-center gap-2">
                  {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(num => (
                    <button
                      key={num}
                      type="button"
                      onClick={() => handleRatingChange(num)}
                      className={`w-12 h-12 flex items-center justify-center border border-gray-300 dark:border-gray-600 rounded-full text-lg font-semibold ${rating >= num ? 'bg-[#598c89] text-white' : 'bg-gray-200 dark:bg-gray-700 text-gray-700 dark:text-gray-300'
                        } transition-colors duration-300 ease-in-out hover:bg-[#598c89] hover:text-white`}
                    >
                      {num}
                    </button>
                  ))}
                </div>
              </div>
              <div className="flex justify-end gap-2">
                <button
                  type="button"

                  onClick={closeModal}
                  className="bg-red-600 text-white px-6 py-2 rounded-lg shadow-md hover:bg-red-700 transition-colors duration-300 ease-in-out dark:bg-red-700 dark:hover:bg-red-800"
                >
                  Cancelar
                </button>
                <button
                  type="submit"
                  className="bg-[#a7eb51] text-white px-6 py-2 rounded-lg shadow-md hover:bg-[#9ac561] transition-colors duration-300 ease-in-out dark:bg-[#89c43e] dark:hover:bg-[#79b238]"
                >
                  Enviar Avaliação
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {isModalRemarcacaoOpen && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-70 flex justify-center items-center z-50">
          <div className="bg-white dark:bg-gray-900 p-8 rounded-lg shadow-lg max-w-md w-full relative z-50">
            <h2 className="text-2xl font-bold mb-6 text-gray-900 dark:text-gray-200">Reagendar Consulta</h2>
            <p className="text-gray-700 dark:text-gray-300 mb-4">Escolha o período para reagendamento:</p>
            <div className="flex flex-col space-y-4 mb-6">
              <label className="flex items-center space-x-3">
                <input
                  type="checkbox"
                  value="10"
                  checked={selectedDays === 10}
                  onChange={handleDaysChange}
                  className="w-4 h-4 text-blue-900 dark:text-blue-400 bg-gray-100 dark:bg-gray-700 border-gray-300 dark:border-gray-600 rounded focus:ring-blue-900 dark:focus:ring-blue-400 focus:ring-2"
                />
                <span className="text-gray-700 dark:text-gray-300">10 Dias</span>
              </label>
              <label className="flex items-center space-x-3">
                <input
                  type="checkbox"
                  value="15"
                  checked={selectedDays === 15}
                  onChange={handleDaysChange}
                  className="w-4 h-4 text-blue-900 dark:text-blue-400 bg-gray-100 dark:bg-gray-700 border-gray-300 dark:border-gray-600 rounded focus:ring-blue-900 dark:focus:ring-blue-400 focus:ring-2"
                />
                <span className="text-gray-700 dark:text-gray-300">15 Dias</span>
              </label>
              <label className="flex items-center space-x-3">
                <input
                  type="checkbox"
                  value="20"
                  checked={selectedDays === 20}
                  onChange={handleDaysChange}
                  className="w-4 h-4 text-blue-900 dark:text-blue-400 bg-gray-100 dark:bg-gray-700 border-gray-300 dark:border-gray-600 rounded focus:ring-blue-900 dark:focus:ring-blue-400 focus:ring-2"
                />
                <span className="text-gray-700 dark:text-gray-300">20 Dias</span>
              </label>
            </div>
            <div className="flex justify-end space-x-4">
              <button
                onClick={closeModalRemarcacao}
                className="bg-red-600 dark:bg-red-700 text-white px-6 py-2 rounded-lg shadow-md hover:bg-red-700 dark:hover:bg-red-800 transition-colors duration-300 ease-in-out"
              >
                Fechar
              </button>
              <button
                onClick={handleConfirm}
                className="bg-[#a7eb51] dark:bg-[#89c43e] text-white px-6 py-2 rounded-lg shadow-md hover:bg-[#9ac561] dark:hover:bg-[#79b238] transition-colors duration-300 ease-in-out"
              >
                Confirmar
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}