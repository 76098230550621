import React, { useEffect, useState } from "react";
import { TittleCustom } from "../../../components/TittleCustom";
import InputCustom from "../../../components/InputCustom";
import SelectEspecialidadeCustum from "../meuperfil/dadosprofissionais/especialidades/SelectEspecialidadesCustum";
import { Pagination } from "flowbite-react";
import { store } from "../../../redux/store";
import ButtonAppointmentScheduling from "../../../components/ButtonAppointmentScheduling";
import withRouter from "../../../helpers/withRouter";
import api_app from "../../../apis/api_app";
import { Accordion } from "flowbite-react";
import ButtonNewQueryCustom from "../../../components/ButtonNewQueryCustom";
import SpinnerCustom from "../../../components/SpinnerCustom";
import { formatMoneyToBRL } from "../../../helpers/moneyHelpers";
import {
  parseDateStringToDate,
  parseDateToDMYFormat,
  validateDateFormat,
  validateDMYFormat,
  getUtcStartEndWithCurrentHour,
  getDatesWithCurrentAndEndHour,
  extractTimeFromDateString
} from "../../../helpers/date_helpers";

export const Agenda = (props) => {
  const [agendas, setAgendas] = useState([]);
  const [minhaAgenda, setMinhaAgenda] = useState([]);
  const [profissaoModel, setProfissaoModel] = useState({});
  const [especialidadesId, setEspecialidadesId] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(50);
  const [totalpg, setTotalpg] = useState(25);
  const [startDate, setStartDate] = useState(parseDateToDMYFormat(new Date()))
  const [endDate, setEndDate] = useState(parseDateToDMYFormat(new Date()))
  const [filter, setFilter] = useState({});
  const [loading, setLoading] = useState(true);

  const getFilter = () => {
    const dates = getDatesWithCurrentAndEndHour(startDate, endDate)

    return {
      inicial_date: dates.startWithCurrentTime,
      final_date: dates.endOfDay,
      especialidades: filter.especialidades ?? '',
      page: currentPage,
      limit: totalpg,
      pessoa_id: store.getState().user.user.id || 1,
      profissao: filter.profissao,
      nome: filter.nome_profissional
    }
  }

  const fetchAgendas = (page) => {
    api_app.getAgendaMedicos(getFilter()).then((resp) => {
      const data = resp.data
      setAgendas(data.data)
      // setTotalpg(data.totalPages)
      setCurrentPage(data.page)
      setLoading(false)
    });
  };


  const fetchAgendasPessoa = (page) => {
    api_app.getAgendaPessoa(getFilter()).then((resp) => {
      const data = resp.data
      setMinhaAgenda(data.data)
      // setTotalpg(data.totalPages)
      setCurrentPage(data.page)
      setLoading(false)
    });
  };

  useEffect(() => {
    fetchAgendas()
    fetchAgendasPessoa()
  }, [])

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case 'periodo_inicial':
        setStartDate(value)
        const now = new Date();
        const nowAsDMYFormat = parseDateToDMYFormat(now);
        if (validateDateFormat(value)) {
          const startInputAsDate = parseDateStringToDate(value);
          const endInputAsDate = parseDateStringToDate(endDate)
          if (startInputAsDate < now) {
            setStartDate(nowAsDMYFormat)
          }
          if (startInputAsDate > endInputAsDate) {
            setEndDate(value)
          }
        } else if (validateDMYFormat(value)) {
          setStartDate(nowAsDMYFormat)
        }
        break;
      case 'periodo_final':
        const startInputAsDate = parseDateStringToDate(startDate)
        const endInputAsDate = parseDateStringToDate(value)
        setEndDate(value)
        if (validateDateFormat(value) && (endInputAsDate < startInputAsDate)) {
          setEndDate(startDate);
        }
        break;
    
      default:
        break;
    }
  };

  const shouldDisplayAppointment = (appointment) => {
    return (
      appointment.status_agenda.id === 2 ||
      appointment.status_agenda.id === 3 ||
      appointment.status_agenda.id === 4
    );
  };

  const filterAgenda = (event) => {
    event.preventDefault();
    setLoading(true)
    api_app.getAgendaMedicos(getFilter()).then((resp) => {
      const data = resp.data
      setAgendas(data.data)
      // setTotalpg(data.totalPages)
      setCurrentPage(data.page)
      setLoading(false)
    }).catch((error) => {
      console.error('Erro ao buscar agendas:', error);
    });
  };

  const handleChangeEspecialidade = (event) => {
    const { name, value } = event.target;
    if (name === 'profissao') {
      setEspecialidadesId("Selecione")
      setFilter({
        ...filter,
        especialidades: undefined,
        profissao: value
      })
    } else {
      setEspecialidadesId(value)
      setFilter({
        ...filter,
        especialidades: value
      })
    }
  };

  const handleNomeProfissionalChange = (event) => {
    const { name, value } = event.target;
    setFilter({
      ...filter,
      [name]: value
    })
  }

  const handlePageChange = (page) => {
    setCurrentPage(page)
    fetchAgendas(page)
  };

  return (
    <>
      <Accordion>
        <Accordion.Panel>
          <Accordion.Title>
            <TittleCustom name="Minhas Consultas" />
          </Accordion.Title>
          <Accordion.Content>
            <div className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
              <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th scope="col" className="px-6 py-3">
                      Data
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Hora
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Profissional
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Especialidade
                    </th>
                    <th scope="col" className="px-6 py-3">
                      situação
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Valor
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Acessar consulta
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {minhaAgenda.map((agenda) => {
                    return (
                      shouldDisplayAppointment(agenda) && (
                        <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                          <td className="px-6 py-3">
                            {agenda.data ? parseDateToDMYFormat(new Date(agenda.data)) : '-'}
                          </td>
                          <td className="px-6 py-3">
                            {agenda.data ? extractTimeFromDateString(new Date(agenda.data)) : '-'}
                          </td>
                          <td className="px-6 py-3">
                            {agenda.pessoa?.nome}
                          </td>
                          <td className="px-6 py-3">
                            {agenda.especialidade?.especialidade}
                          </td>
                          <td className="px-6 py-3">
                            {agenda.status_agenda?.status_agenda}
                          </td>
                          <td className="px-6 py-3">
                            {formatMoneyToBRL(agenda.valor)}
                          </td>
                          <td className="px-6 py-3">
                            <ButtonNewQueryCustom
                              link={'/dados-profissionais/agenda/atendimento-consulta/' + agenda.id}
                              name="Consultar"
                            />
                          </td>
                        </tr>))
                  })}
                </tbody>
              </table>
            </div>
            <div className="flex items-center justify-end mt-4">
              <div className="flex sm:justify-center">
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalpg}
                  onPageChange={handlePageChange}
                  showIcons
                  previousLabel="anterior"
                  nextLabel="próxima"
                />
              </div>
            </div>
          </Accordion.Content>
        </Accordion.Panel>
      </Accordion>
      <div>
        <TittleCustom
          name="Agendamento de Consulta"
        />
      </div>
      <div>
        <div className="mb-5">
          <form onSubmit={filterAgenda}>
            <div className="grid gap-6 md:grid-cols-2">
              <InputCustom
                  label="Período Inicial:"
                  value={startDate}
                  type="text"
                  mask='99/99/9999'
                  name="periodo_inicial"
                  onChange={handleFilterChange}
                  placeholder="00/00/0000"
              />
              <InputCustom
                  label="Período Final:"
                  value={endDate}
                  type="text"
                  mask='99/99/9999'
                  name="periodo_final"
                  onChange={handleFilterChange}
                  placeholder="00/00/0000"
              />
              <SelectEspecialidadeCustum
                profissao_id={profissaoModel?.id || 0}
                value={especialidadesId || ""}
                onChange={handleChangeEspecialidade}
              />
              <InputCustom
               label="Nome do Profissional:"
               value={filter.nome_profissional}
               type="string"
               name="nome_profissional"
               placeholder="Nome do Profissional"
               onChange={handleNomeProfissionalChange}

             />
              <div>
                <button
                  type="submit"
                  className="inline-flex items-center mt-6 py-2.5 px-3 ms-2 text-sm font-medium text-white bg-blue-900 rounded-lg border border-blue-800 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-900 dark:hover:bg-blue-800 dark:focus:ring-blue-800"
                >
                  <svg
                    className="w-4 h-4 me-2"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 20"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                    />
                  </svg>
                  Pesquisar
                </button>
              </div>

            </div>
          </form>
        </div>
        {loading ? (
          <div className="flex justify-center items-center h-screen bg-gray-100">
            <SpinnerCustom />
          </div>
        ) : (
          <div>
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
              <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th scope="col" className="px-6 py-3">
                      PROFISSIONAL
                    </th>
                    <th scope="col" className="px-6 py-3">
                      ESPECIALIDADE
                    </th>
                    <th scope="col" className="px-6 py-3 text-center">
                      OPÇÕES
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {agendas.map((agenda) => (
                    <tr key={agenda.id} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                      <td scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        {agenda?.nome}
                      </td>
                      <td className="px-6 py-4">
                        {agenda?.pessoa_especialidade?.map((z, index) => {
                          let separator = ''
                          if (index >= 1) separator = ' - '
                          return (
                            <span key={index}>{separator}{z.especialidade?.especialidade}</span>
                          )
                        })}
                      </td>
                      <td className="px-6 py-4 text-center">
                        <div className="flex items-center justify-center w-full">
                          <ButtonAppointmentScheduling
                            link={"/agendamento-de-consulta/agendar-consulta/" + agenda.id}
                            name="Agendar Consulta"
                          />
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>


            </div>
            <div className="flex items-center justify-end mt-4">
              <div className="flex overflow-x-auto sm:justify-center">
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalpg}
                  onPageChange={handlePageChange}
                  showIcons
                  previousLabel="anterior"
                  nextLabel="próxima"
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default withRouter(Agenda);
