import React from "react";
import { KonvaButton, KonvaIcon } from "../konvaSketchBoard/styles";

const KonvaEraserButton = ({ setTool, tool }) => {
    return (
        <KonvaButton onClick={setTool}>
            <KonvaIcon
                size={22}
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path fill={tool !== "eraser" ? "#0000007e" : "#000000"} d="M17.9 6.4L14.7 3.2a1.6 1.6 0 0 0-2.26 0L3.8 11.84a1.6 1.6 0 0 0 0 2.26l2 2A.533.533 0 0 0 6.34 16H16a.533.533 0 0 0 0-1.07h-5.63l5.91-5.92a1.6 1.6 0 0 0 0-2.26zM17.11 8.24L12.8 12.55 8.49 8.24 12.81 3.93a.533.533 0 0 1 .76 0l2.78 2.79a.533.533 0 0 1 0 .76z"></path>
            </KonvaIcon>
        </KonvaButton>
    )
}

export default KonvaEraserButton