import React, { useState } from "react";
import { TittleCustom } from "../../../../../components/TittleCustom";
import { ButtonReturnCustom } from "../../../../../components/ButtonReturnCustom";
import { ButtonSaveCustom } from "../../../../../components/ButtonSaveCustom";
import SelectEspecialidadeCustum from "./SelectEspecialidadesCustum";
import InputCustom from "../../../../../components/InputCustom";
import { pessoa_especialidadeModel, pessoaModel, profissaoModel } from "../../../../../resource/request";
import { store } from "../../../../../redux/store";
import api_app from "../../../../../apis/api_app";
import withRouter from "../../../../../helpers/withRouter";
import { useDispatch } from "react-redux";
import { createUser } from "../../../../../redux/user/slice";
import FileInputCustom from "../../../../../components/FileInputCustom";

const CreateEspecialidade = (props: any) => {
    const [pessoa, setPessoa] = useState<pessoaModel>(store.getState().user?.user);
    const [profissaoModel, setProfissaoModel] = useState<profissaoModel>({} as profissaoModel);
    const [especialidades, setEspecialidades] = useState<pessoa_especialidadeModel>({
        pessoa_id: store.getState().user?.user.id,
        flg_status: 'A',
        flg_visivel: 'S',
        dat_criado_em: new Date(),
        num_especialidade_rqe: "",
        valor_atendimento_pa: 0,
        vlr_atendimento: 0,
    } as pessoa_especialidadeModel);
    const [msg, setMsg] = useState<string>("");
    const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
    const [emailPrincipal, setEmailPrincipal] = useState<String>(store.getState().user.emailPrincipal);
    let dispach = useDispatch();

    const updateStore = () => {     
        api_app.findByPessoa(emailPrincipal).then(result => {
            if (result) {
              dispach(createUser(result));
            } 
          });   
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = event.target;

        if (name === 'profissao') {
            setProfissaoModel({
                ...profissaoModel,
                id: parseInt(value),
            });
        } else {
            setEspecialidades({
                ...especialidades,
                [name]: value,
            });
        }
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        especialidades.especialidade_id = parseInt(especialidades?.especialidade_id?.toString() || "");
        api_app.criarEspecialidade(especialidades).then(() => {
            setMsg("Seus dados foram atualizados com sucesso!");
            setIsSubmitted(true);
            updateStore();
        });
    };

    return (
        <>
            <form onSubmit={handleSubmit}>
                <div className="flex items-center justify-between rounded-t dark:border-gray-600">
                    <TittleCustom name="Adicionar Especialidade" />
                    <ButtonReturnCustom link="/dados-profissionais" />
                </div>
                <div>
                    <div className="grid gap-6 mb-6 md:grid-cols-2">
                        <SelectEspecialidadeCustum onChange={handleChange} />
                        <InputCustom
                            label="UF:"
                            value={especialidades.uf || ''}
                            type="text"
                            name="uf"
                            placeholder="UF"
                            onChange={handleChange}
                        />
                        <InputCustom
                            label="Nº Registro/Nº RQE:"
                            value={especialidades.num_especialidade_rqe || ''}
                            type="text"
                            name="num_especialidade_rqe"
                            placeholder="Nº Especialidades/ Nº RQE"
                            onChange={handleChange}
                        />
                        <FileInputCustom
                            label="Título De Especialidade (Opcional):"
                            name="titulo-de-especialidade"
                            value=""
                        />
                        <InputCustom
                            label="Valor da Consulta:"
                            value={especialidades?.vlr_atendimento?.toString() || ''}
                            type="text"
                            name="vlr_atendimento"
                            placeholder="Valor da Consulta"
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div className="flex items-center mt-6 space-x-4 rtl:space-x-reverse">
                    {!isSubmitted && (
                        <ButtonSaveCustom type="submit" name="Salvar" />
                    )}
                </div>
                <p className="mt-2 text-sm text-green-600 dark:text-green-500">{msg}</p>
            </form>
        </>
    );
};

export default withRouter(CreateEspecialidade);
