import React, { useEffect, useState } from 'react';
import { useKeycloak } from '@react-keycloak/web';

const CheckUserGroup: React.FC<{ groupName: string }> = ({ groupName }) => {
    const { keycloak } = useKeycloak();
    const [isInGroup, setIsInGroup] = useState(false);

    useEffect(() => {
        if (keycloak.authenticated) {
            keycloak.loadUserInfo().then((userInfo) => {
                fetch(`${keycloak.authServerUrl}/admin/realms/${keycloak.realm}/users/${keycloak.subject}/groups`, {
                    headers: {
                        Authorization: `Bearer ${keycloak.token}`,
                    },
                })
                    .then((response) => response.json())
                    .then((groups) => {
                        console.log(groups);
                        const userIsInGroup = groups.some((group: any) => group.name === groupName);
                        setIsInGroup(userIsInGroup);
                    })
                    .catch((error) => {
                        console.error('Error fetching user groups:', error);
                    });
            });
        }
    }, [keycloak, groupName]);

    return (
        <div>
            {isInGroup ? (
                <p>User is in the group: {groupName}</p>
            ) : (
                <p>User is not in the group: {groupName}</p>
            )}
        </div>
    );
};

export default CheckUserGroup;
