import styled from 'styled-components';

export const NotificationWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 16px;
  border-radius: 8px;
  background-color: ${({ mode }) =>
    mode === 'warning' ? 'rgba(251, 222, 192)'
    : mode === 'error' ? 'rgba(255, 235, 236)'
    : 'rgba(230, 249, 239)'};
  color: #111827;
  font-size: 16px;
  margin: 16px 0;
`;

export const IconWrapper = styled.div`
  margin-right: 10px;
  font-size: 20px;
  color: ${({ mode }) =>
    mode === 'warning' ? '#f9962f'
    : mode === 'error' ? '#c53434'
    : '#207c50'};
`;

export const Message = styled.div`
  flex: 1;
`;