import React from "react";
import {
    KonvaModalButton,
    KonvaModalFooter,
    KonvaTitle
} from "../konvaSketchBoard/styles";
import { KonvaTextModalContainer, KonvaTextModalContent } from "./styles";

const KonvaInputTextModal = ({ onChange, onCancel, onAdd }) => {
    return (
        <KonvaTextModalContainer>
            <KonvaTitle>Inserir texto</KonvaTitle>
            <KonvaTextModalContent
                onChange={(e) => onChange(e.target.value)}
                placeholder="Digite aqui..."
            />
            <KonvaModalFooter>
                <KonvaModalButton
                    onClick={onCancel}
                    $variant="cancel"
                >
                    Cancelar
                </KonvaModalButton>
                <KonvaModalButton
                    onClick={onAdd}
                    $variant="add"
                >
                    Adicionar
                </KonvaModalButton>
            </KonvaModalFooter>
        </KonvaTextModalContainer>
    )
}

export default KonvaInputTextModal