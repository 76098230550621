import Keycloak from "keycloak-js";
import { jwtDecode } from "jwt-decode";

const keycloak = new Keycloak({
  url: process.env.REACT_APP_KEYCLOAK_URL,
  realm: process.env.REACT_APP_KEY_CLOAK_REALM,
  clientId: process.env.REACT_APP_KEY_CLOAK_CLIENT_ID
});
const initKeycloak = (onAuthenticatedCallback) => {
  keycloak.init({ onLoad: 'login-required' }).then((authenticated) => {
    if (authenticated) {
      onAuthenticatedCallback()

    } else {
      console.warn('Not authenticated!');
    }
  }).catch((error) => {
    console.error('Failed to initialize Keycloak', error);
  });
};

const getIdToken = () => {
  if (keycloak.authenticated) {
    return keycloak.token;
  } else {
    return null;
  }
};

const loadUserInfo = async () => {
  if (keycloak.authenticated) {
    return await keycloak.loadUserInfo();
  } else {
    return null;
  }
};

const hasGroup = (group) => {
  if (keycloak.authenticated) {
    const decodedToken = jwtDecode(keycloak.token);
    return !!decodedToken?.groups?.includes(group);
  }
}

export { keycloak, initKeycloak, getIdToken, loadUserInfo, hasGroup };
