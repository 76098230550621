const isoDateTimeRegex = /^\d{4}-\d{2}-\d{2}(?:[ T]\d{2}:\d{2}:\d{2}(?:\.\d{3})?(?:Z)?)?$/;
const isoDateRegex = /^\d{4}-\d{2}-\d{2}$/;
const dmyDateRegex = /^(\d{2})\/(\d{2})\/(\d{4})$/;
const timeRegex = /^(\d{2})\:(\d{2})$/;
const timePatternRegex = /^([01]\d|2[0-3]):([0-5]\d)$/;

export const validateDMYFormat = (dateString) => dateString.match(dmyDateRegex);

export const isValid24HourTime = (timeStr) => timePatternRegex.test(timeStr);

export const validateTimeFormat = (time) => time.match(timeRegex)

export const validateDateFormat = (dateString) => {
  const match = validateDMYFormat(dateString) ?? false

  if (match) {
    const day = parseInt(match[1], 10);
    const month = parseInt(match[2], 10);
    const year = parseInt(match[3], 10);
  
    if (day < 1 || day > 31 || month < 1 || month > 12 || year < 1970) {
      return false;
    }

    const isLeapYear = (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0);
  
    const daysInMonth = [31, (isLeapYear ? 29 : 28), 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    if (day > daysInMonth[month - 1]) {
      return false;
    }
  
    return true;
  }
  return false;
}

export const calculateAgeToMeusPacientesFormat = (fullDateString) => {
  const convertedDateString = parseDateToDMYFormat(fullDateString);
  const [day, month, year] = convertedDateString.split('/').map(Number);
  const dateOfBirthObj = new Date(year, month - 1, day);
  const today = new Date();
  let age = today.getFullYear() - dateOfBirthObj.getFullYear();

  const isBirthdayPassed = 
  today.getMonth() > dateOfBirthObj.getMonth() || 
  (today.getMonth() === dateOfBirthObj.getMonth() && today.getDate() >= dateOfBirthObj.getDate());
  
  if (!isBirthdayPassed) {
  age--;
  }

  return `${day}/${month}/${year} - ${age} ${age > 1 ? 'anos' : 'ano'}`;
};

export const parseDateStringToDate = (dateString) => {
  if (validateDMYFormat(dateString)) {
    const [day, month, year] = dateString.split('/');
    return new Date(`${year}-${month}-${day}T00:00`);
  }
  return dateString
};

export const joinDateTime = (date, time, isUtc) => {
  let dateObj;
  let timeObj;

  if (typeof date === 'string') {
      const [day, month, year] = date.split('/').map(Number);
      dateObj = new Date(year, month - 1, day);
  } else if (date instanceof Date) {
      dateObj = new Date(date);
  } else {
      throw new Error('Invalid date format');
  }

  if (typeof time === 'string') {
      const [hours, minutes] = time.split(':').map(Number);
      timeObj = { hours, minutes };
  } else if (time instanceof Date) {
      timeObj = { hours: time.getHours(), minutes: time.getMinutes() };
  } else {
      throw new Error('Invalid time format');
  }

  const combinedDate = new Date(
      dateObj.getFullYear(),
      dateObj.getMonth(),
      dateObj.getDate(),
      timeObj.hours,
      timeObj.minutes
  );

  if (isUtc) {
      const utcDate = new Date(combinedDate.getTime() - combinedDate.getTimezoneOffset() * 60000);
      return utcDate;
  }

  return combinedDate;
}

export const increaseTimeOnDate = (startTime, valueToIncrease) => {
  const [hours, minutes] = valueToIncrease.split(':').map(Number);
  const totalMilliseconds = (hours * 60 + minutes) * 60 * 1000;
  return new Date(startTime.getTime() + totalMilliseconds);
};

export const validateEndTime = (startTime, endTime, duration) => {
  const timeToMinutes = (time) => {
    const [hours, minutes] = time.split(':').map(Number);
    return hours * 60 + minutes;
  };
  const startMinutes = timeToMinutes(startTime);
  const endMinutes = timeToMinutes(endTime);
  const durationMinutes = timeToMinutes(duration);

  const timeDifference = endMinutes - startMinutes;
  return timeDifference < durationMinutes;
}

export const increaseTimeOnTimeOnly = (initialHour, interval) => {
  const [hour, minute] = initialHour.split(':').map(Number);
  const [hoursToAdd, minutesToAdd] = interval.split(':').map(Number);

  const newMinutes = minute + minutesToAdd;
  const extraHours = Math.floor(newMinutes / 60);
  const finalMinutes = newMinutes % 60;

  const newHour = (hour + hoursToAdd + extraHours) % 24;

  return `${String(newHour).padStart(2, '0')}:${String(finalMinutes).padStart(2, '0')}`;
};

export const extractTimeFromDateString = (dateString, ) => {
  if (dateString instanceof Date) {
    const hours = String(dateString.getHours()).padStart(2, '0');
    const minutes = String(dateString.getMinutes()).padStart(2, '0');
    return `${hours}:${minutes}`;
  } else {
    const time = dateString.split('T')[1].slice(0, 5)
    return time
  }
}

export const parseDateToDMYFormat = (date) => {
  let isoDate;
  const createDateFromString = (dateString) => new Date(dateString);

  if (date instanceof Date) {
    isoDate = date
  } else if (isoDateRegex.test(date)) {
    isoDate = createDateFromString(`${date}T00:00:00`);
  } else if (isoDateTimeRegex.test(date)) {
    if (date.includes('Z')) {
      const regionalDate = date.slice(0, -1);
      isoDate = new Date(regionalDate);
    } else {
      isoDate = new Date(date);
    }
  } else {
    isoDate = new Date();
  }

  const day = String(isoDate.getDate()).padStart(2, '0');
  const month = String(isoDate.getMonth() + 1).padStart(2, '0');
  const year = isoDate.getFullYear();

  return `${day}/${month}/${year}`;
};

export const parseToYMD = (date) => {
  if (date instanceof Date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
  
    return `${year}-${month}-${day}`;
  } else if (dmyDateRegex.test(date)) {
    const [day, month, year] = date.split('/');
    return `${year}-${month}-${day}`;
  } else if (isoDateTimeRegex.test(date)) {
    return date.slice(0, 10)
  } else return date
}

export const getDatesWithCurrentAndEndHour = (startDateStr, endDateStr) => {
  function parseDate(dateStr) {
    const [day, month, year] = dateStr.split('/').map(Number);
    return new Date(year, month - 1, day);
  }

  const startDate = parseDate(startDateStr);
  const endDate = parseDate(endDateStr);

  const now = new Date();

  const startWithCurrentTime = new Date(startDate.setHours(now.getHours(), now.getMinutes(), now.getSeconds(), now.getMilliseconds()));

  const endOfDay = new Date(endDate.setHours(23, 59, 59, 999));

  return {
    startWithCurrentTime,
    endOfDay
  };
};
