import React, { useState, useEffect } from "react";
import { TittleCustom } from "../../../../../components/TittleCustom";
import { ButtonReturnCustom } from "../../../../../components/ButtonReturnCustom";
import { ButtonSaveCustom } from "../../../../../components/ButtonSaveCustom";
import InputCustom from "../../../../../components/InputCustom";
import SelectEspecialidadeCustum from "./SelectEspecialidadesCustum";
import withRouter from "../../../../../helpers/withRouter";
import { pessoa_especialidadeModel, profissaoModel } from "../../../../../resource/request";
import { store } from "../../../../../redux/store";
import { useDispatch } from "react-redux";
import api_app from "../../../../../apis/api_app";
import { createUser } from "../../../../../redux/user/slice";
import FileInputCustom from "../../../../../components/FileInputCustom";

const EditEspecialidade = (props: any) => {
    const [especialidades, setEspecialidades] = useState<pessoa_especialidadeModel>({} as pessoa_especialidadeModel);
    const [profissao, setProfissao] = useState<profissaoModel>({} as profissaoModel);
    const [msg, setMsg] = useState<string>("");
    const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
    const [emailPrincipal, setEmailPrincipal] = useState<String>(store.getState().user.emailPrincipal);
    let dispach = useDispatch();

    useEffect(() => {
        buscarDadosDoBanco();
    }, []);

    const buscarDadosDoBanco = () => {
        const id = props.router.params.id;
        const espec = store.getState().user.user.pessoa_especialidade;
        espec.map((especialidade) => {
            if (especialidade?.id?.toString() === id.toString()) {
                setEspecialidades(especialidade);
            }
        });

    };

    const updateStore = () => {     
        api_app.findByPessoa(emailPrincipal).then(result => {
            if (result) {
              dispach(createUser(result));
            } 
          });   
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = event.target;
        if (name === 'profissao') {
            setProfissao({ ...profissao, id: parseInt(value) });
        } else {
            setEspecialidades({ ...especialidades, [name]: value });
        }
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setIsSubmitted(true);
        try{
            const response = await api_app.atualizarEspecialidade(especialidades.id, especialidades);
            setMsg("Seus dados foram atualizados com sucesso!");        
            updateStore();
            setIsSubmitted(false);
        } catch(e) {
            console.log(e);
            setMsg("Ocorreu um erro ao salvar os dados");
            setIsSubmitted(false);
        }

    };
    return (
        <>
            <form onSubmit={handleSubmit}>
                <div className="flex items-center justify-between rounded-t dark:border-gray-600">
                    <TittleCustom name="Alterar Especialidade" />
                    <ButtonReturnCustom link="/dados-profissionais" />
                </div>
                <div className="grid gap-6 mb-6 md:grid-cols-2">
                    {especialidades.especialidade && 
                    <SelectEspecialidadeCustum 
                        onChange={handleChange} 
                        value={especialidades?.especialidade_id?.toString()}
                        valueProfissao={especialidades?.especialidade?.profissao_id?.toString()}
                        profissao_id={especialidades?.especialidade?.profissao_id}
                    />}
                    <InputCustom
                        label="UF:"
                        value={especialidades.uf || ''}
                        type="text"
                        name="uf"
                        placeholder="UF"
                        onChange={handleChange}
                    />
                    <InputCustom
                        label="Nº Registro/Nº RQE:"
                        value={especialidades.num_especialidade_rqe || ''}
                        type="text"
                        name="num_especialidade_rqe"
                        placeholder="Nº Especialidades/ Nº RQE"
                        onChange={handleChange}
                    />
                    <FileInputCustom
                        label="Título De Especialidade (Opcional):"
                        name="titulo-de-especialidade"
                        value=""
                    />
                    <InputCustom
                        label="Valor da Consulta:"
                        value={especialidades?.vlr_atendimento?.toString() || ''}
                        type="text"
                        name="vlr_atendimento"
                        placeholder="Valor da Consulta"
                        onChange={handleChange}
                    />
                </div>
                <div className="flex items-center mt-6 space-x-4 rtl:space-x-reverse">
                    <ButtonSaveCustom
                        type=""
                        name="Salvar"
                        disabled={isSubmitted}
                    />
                </div>
                <p className="mt-2 text-sm text-green-600 dark:text-green-500">{msg}</p>
            </form>
        </>
    );
};

export default withRouter(EditEspecialidade);
