import React, { Component } from "react";
import { TittleCustom } from "../../../../components/TittleCustom";
import { ButtonSaveCustom } from "../../../../components/ButtonSaveCustom";
import InputCustom from "../../../../components/InputCustom";
import SelectCustom, { Option } from "../../../../components/SelectCustom";
import {
    pessoa_fisicaModel,
    pessoa_profissionalModel,
    pessoaModel,
    status_cadastroModel
} from "../../../../resource/request";
import { store } from "../../../../redux/store";
import api_app from "../../../../apis/api_app";
import withRouter from "../../../../helpers/withRouter";
import SpinnerCustom from "../../../../components/SpinnerCustom";
import { createUser } from "../../../../redux/user/slice";
import FileInputCustom from "../../../../components/FileInputCustom";
import FilePhotoCustom from "../../../../components/FilePhoto";
import { parseDateToDMYFormat } from "../../../../helpers/date_helpers";

class FormPessoa extends Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            tiposPessoa: [
                { key: "F", value: "Pessoa Física", disabled: false },
                { key: "J", value: "Pessoa Jurídica", disabled: false }
            ],
            generos: [{ key: "0", value: "Carregando...", disabled: true }],
            etnias: [{ key: "0", value: "Carregando...", disabled: true }],
            tipoIdentidadeGenero: [{ key: "1", value: "Carregando...", disabled: true }],
            tipoGeneroPronome: [{ key: "1", value: "Carregando...", disabled: true }],
            estadoCivil: [{ key: "0", value: "Carregando...", disabled: true }],
            pessoa: store.getState().user?.user,
            pessoa_profissional: {} as pessoa_profissionalModel,
            pessoa_fisica: store.getState().user?.user?.pessoa_fisica?.find(() => true),
            msg: '',
            loading: (this?.state?.pessoa.id || false),
            dataNas: parseDateToDMYFormat(store.getState().user?.user?.dat_nascimento_fundacao),
            isSubmitted: false,
        };
    }

    componentDidMount(): void {
        if (this.state.pessoa.id == null) {
            api_app.findByPessoa(this.props.router.keycloak?.idTokenParsed?.email).then(result => {
                this.props.router.dispach(createUser(result));
                const pessoa_fisica = store.getState().user?.user?.pessoa_fisica?.find(() => true);

                this.setState({
                    pessoa: result,
                    pessoa_fisica
                });
            }).then(() => {
                this.buscarDadosDoBanco().then(() => {
                    console.log(this.state.pessoa.cpf_cnpj);
                    this.setState({ loading: false });
                });
            })
        } else {
            this.buscarDadosDoBanco().then(() => {
                this.setState({ loading: false });
            });
        }
    }

    async buscarDadosDoBanco() {
        try {
            const generosResponse = await this.getGeneros();
            const etniasResponse = await this.getEtnias();
            const estadoCivilREsponse = await this.getEstadoCivil();
            const tipoIdentidadeGeneroResponse = await this.getTipoIdentidadeGenero();
            const tipoGeneroPronomeResponse = await this.getTipoGeneroPronome();
            const generos = generosResponse.map((genero: any) => ({
                key: genero.id.toString(),
                value: genero.tipo_genero,
                disabled: false
            }));

            const etnias = etniasResponse.map((etnia: any) => ({
                key: etnia.id.toString(),
                value: etnia.raca_etnia,
                disabled: false
            }));

            const estadoCivil = estadoCivilREsponse.map((estadoCv: any) => ({
                key: estadoCv.id.toString(),
                value: estadoCv.estado_civil,
                disabled: false
            }));

            const tipoIdentidadeGenero = tipoIdentidadeGeneroResponse.map((data: any) => ({
                key: data.id.toString(),
                value: data.tipo_identidade_genero,
                disabled: false
            }));
            const tipoGeneroPronome = tipoGeneroPronomeResponse.map((data: any) => ({
                key: data.id.toString(),
                value: data.tipo_genero_pronome,
                disabled: false
            }));
            this.setState({
                generos,
                etnias,
                tipoIdentidadeGenero,
                tipoGeneroPronome,
                estadoCivil,
                loading: false,
            });

        } catch (error) {
            console.error("Erro ao buscar dados do banco:", error);
            this.setState({ loading: false });
        }
    }

    async getGeneros(): Promise<any[]> {
        try {
            return await api_app.findTipoGenero();
        } catch (error) {
            console.error("Erro ao obter os gêneros:", error);
            throw error;
        }
    }

    async getEtnias(): Promise<any[]> {
        try {
            return await api_app.findEtnia();
        } catch (error) {
            console.error("Erro ao obter as etnias:", error);
            throw error;
        }
    }

    async getTipoIdentidadeGenero(): Promise<any[]> {
        try {
            return await api_app.findTipoIdentidadeGenero();
        } catch (error) {
            console.error("Erro ao obter as tipos :", error);
            throw error;
        }
    }

    async getTipoGeneroPronome(): Promise<any[]> {
        try {
            return await api_app.findTipoGeneroPronome();
        } catch (error) {
            console.error("Erro ao obter as tipos :", error);
            throw error;
        }
    }

    async getEstadoCivil(): Promise<any[]> {
        try {
            return await api_app.findEstadoCivil();
        } catch (error) {
            console.error("Erro ao obter as tipos :", error);
            throw error;
        }
    }

    handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = event.target;
        this.setState({
            pessoa: {
                ...this.state.pessoa,
                [name]: value
            }

        });
    };
    handleChangePeople = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = event.target;
        this.setState({
            pessoa_fisica: {
                ...this.state.pessoa_fisica,
                pessoa_id: this.state.pessoa.id,
                [name]: value
            }
        });
        if (!isNaN(+event.target.value)) {
            this.setState({
                pessoa_fisica: {
                    ...this.state.pessoa_fisica,
                    [name]: parseInt(value)

                }
            });
        }
    };
    handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        api_app.atualizarPessoa(this.state.pessoa.id, this.state.pessoa).then(x => {

            this.setState({
                pessoa_fisica: {
                    ...this.state.pessoa_fisica,
                    pessoa_id: x.data.id
                },

            })


            api_app.atualizarPessoaFisica(this.state.pessoa_fisica.id || 0, this.state.pessoa_fisica).then(
                () => {
                    const msg = "Seu dados foram atualizados com sucesso!";
                    this.setState({ msg, isSubmitted: true });
                    this.props.router.updateStore();
                }
            )
        }
        )
    };

    render() {
        if (this.state.loading) {
            return <div className="flex justify-center items-center h-screen">
                <SpinnerCustom />
            </div>
        }

        return <>
            <form onSubmit={this.handleSubmit}>
                <div className="flex justify-between items-center text-lg">
                    <TittleCustom name="Dados Pessoais" />
                    <div className="ml-2 text-sm space-y-1">
                        <p>Data de
                            Cadastro: {this.state.pessoa?.dat_criado_em ? new Date(this.state.pessoa.dat_criado_em).toLocaleDateString() : ''}</p>
                        <p>Status do Cadastro: {this.state.pessoa?.status_cadastro?.status_cadastro || ''}</p>
                    </div>

                </div>
                <div className="flex flex-row justify-start items-center mb-6 ">
                    <div className="flex-shrink-0 w-1/2">
                        <FilePhotoCustom
                        />
                    </div>
                    <div className="flex-1 ml-1 flex flex-col items-center mt-4">
                        <div className="mb-2 w-full">
                            <InputCustom
                                label="Nome Completo:"
                                value={this.state.pessoa.nome || ''}
                                type="text"
                                name="nome"
                                placeholder="Nome Completo"
                                required
                            />
                        </div>

                        <div className="mb-2 w-full">
                            <InputCustom
                                label="Nome Social:"
                                value={this.state.pessoa_fisica?.nome_social || ""}
                                type="text"
                                name="nome_social"
                                onChange={this.handleChangePeople}
                                placeholder="Nome Social"
                            />
                        </div>
                        <div className="mb-2 w-full">
                            <InputCustom
                                label="CPF:"
                                value={this.state.pessoa.cpf_cnpj || ''}
                                type="text"
                                mask="999.999.999-99"
                                name="cpf_cnpj"
                                placeholder="000.000.000-00"
                                required
                            />
                        </div>
                        <div className="mb-2 w-full">
                            <InputCustom
                                label="Data de Nascimento:"
                                value={this.state.dataNas}
                                type="text"
                                name=""
                                placeholder="00/00/0000"
                                required
                            />
                        </div>
                    </div>
                </div>
                <div className="grid gap-6 mb-6 md:grid-cols-2">
                    <SelectCustom
                        label="Estado Civil:"
                        name="estado_civil_id"
                        defaultValue={this.state.pessoa?.pessoa_fisica?.find(() => true)?.estado_civil_id.toString() || ""}
                        onChange={this.handleChangePeople}
                        options={this.state.estadoCivil}
                        require

                    />
                    <div className="mb-2 w-full">
                        <InputCustom
                            label="RG/RNE:"
                            value={this.state.pessoa_fisica?.rg || ""}
                            mask="99.999.999-99"
                            type="text"
                            name="rg"
                            onChange={this.handleChangePeople}
                            placeholder="RG/RNE"
                            required

                        />
                    </div>
                    <InputCustom
                        label="Órgão Expedidor/UF:"
                        value={this.state.pessoa_fisica?.rg_orgao_expedidor || ""}
                        type="text"
                        name="rg_orgao_expedidor"
                        onChange={this.handleChangePeople}
                        placeholder="Órgão Expedidor / UF"
                        required

                    />
                    <InputCustom
                        label="Nº Do Cartão Do SUS:"
                        value={this.state.pessoa_fisica?.cartao_sus || ""}
                        type="text"
                        mask="999-9999-9999-9999"
                        name="cartao_sus"
                        onChange={this.handleChangePeople}
                        placeholder="Nº Do Cartão Do SUS"
                    />
                    <InputCustom
                        label="Nome Da Mãe:"
                        value={this.state.pessoa_fisica?.nome_mae || ""}
                        type="text"
                        name="nome_mae"
                        onChange={this.handleChangePeople}
                        placeholder="Nome Da Mãe"
                    />
                    <InputCustom
                        label="Nome Do Pai:"
                        value={this.state.pessoa_fisica?.nome_pai || ""}
                        type="text"
                        name="nome_pai"
                        onChange={this.handleChangePeople}
                        placeholder="Nome Do Pai"


                    />
                    <SelectCustom
                        label="Etnia:"
                        name="raca_etnia_id"
                        defaultValue={this.state.pessoa?.pessoa_fisica?.find(() => true)?.raca_etnia_id.toString() || "1"}
                        onChange={this.handleChangePeople}
                        options={this.state.etnias}
                        require
                    />
                    <SelectCustom
                        label="Gênero:"
                        name="tipo_genero_id"
                        defaultValue={this.state.pessoa?.pessoa_fisica?.find(() => true)?.tipo_genero_id.toString() || "1"}
                        onChange={this.handleChangePeople}
                        options={this.state.generos}
                        require
                    />
                    <SelectCustom
                        label="Pronome Gênero:"
                        defaultValue={this.state.pessoa?.pessoa_fisica?.find(() => true)?.tipo_genero_pronome_id.toString() || "1"}
                        name="tipo_genero_pronome_id"
                        onChange={this.handleChangePeople}
                        options={this.state.tipoGeneroPronome}
                        require
                    />
                    <SelectCustom
                        label="Identidade De Gênero:"
                        defaultValue={this.state.pessoa.pessoa_fisica?.find(() => true)?.tipo_identidade_genero_id.toString() || "1"}
                        name="tipo_identidade_genero_id"
                        onChange={this.handleChangePeople}
                        options={this.state.tipoIdentidadeGenero}
                        require

                    />
                    <InputCustom
                        label="Profissão:"
                        value={this.state.pessoa_fisica?.profissao || ""}
                        type="text"
                        name="profissao"
                        onChange={this.handleChangePeople}
                        placeholder="Profissão"

                    />
                    <InputCustom
                        label="Pis/Pasep:"
                        value={this.state.pessoa_fisica?.pis_pasep || ""}
                        type="text"
                        name="pis_pasep"
                        mask="999.99999.99-9"
                        onChange={this.handleChangePeople}
                        placeholder="Pis/Pasep"

                    />
                </div>
                <div>
                    {!this.state.isSubmitted && (
                        <ButtonSaveCustom
                            type="submit"
                            name="Salvar"
                        />
                    )}
                </div>

                <p className="mt-2 text-sm text-green-600 dark:text-green-500"> {this.state.msg}</p>
            </form>
        </>
    }
}

export default withRouter(FormPessoa);