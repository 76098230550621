import { Component, ReactNode } from "react";
import { TittleCustom } from "../../../../../components/TittleCustom";
import { ButtonReturnCustom } from "../../../../../components/ButtonReturnCustom";
import { ButtonSaveCustom } from "../../../../../components/ButtonSaveCustom";
import InputCustom from "../../../../../components/InputCustom";

export class EditPlanoParticular extends Component {
    render() {
        return <>
            <form>
                <div className="flex items-center justify-between rounded-t dark:border-gray-600">
                    <TittleCustom
                        name="Alterar Plano De Saúde"
                    />
                    <ButtonReturnCustom
                        link="/dados-pacientes"
                    />
                </div>
                <div className="grid gap-6 mb-6 md:grid-cols-2">
                    <InputCustom
                        label="Seguradora:"
                        value=""
                        type="text"
                        name="name"
                        placeholder="Seguradora"
                    />
                    <InputCustom
                        label="Tipo de Plano:"
                        value=""
                        type="text"
                        name="name"
                        placeholder="Tipo de Plano"
                    />
                    <InputCustom
                        label="Número Da Carteirinha:"
                        value=""
                        type="number"
                        name="name"
                        placeholder="Número Da Carteirinha"
                    />
                    <InputCustom
                        label="Cobertura:"
                        value=""
                        type="text"
                        name="name"
                        placeholder="Cobertura"
                    />
                </div>
                <div>
                    <InputCustom
                        label="Titular:"
                        value=""
                        type="text"
                        name="name"
                        placeholder="Titular"
                    />
                </div>
                <div className="flex items-center mt-6 space-x-4 rtl:space-x-reverse">
                    <ButtonSaveCustom
                         type="submit"
                        name="Salvar"
                    />
                </div>
            </form>
        </>
    }
}