import React, { useEffect, useState } from "react";
import SelectCustom from "../../../components/SelectCustom";
import CheckboxCustum from "../../../components/CheckboxCustum";
import { ButtonSaveCustom } from "../../../components/ButtonSaveCustom";
import { AboutCustumPage } from "../../../components/AboutCustom";
import { ButtonReturnCustom } from "../../../components/ButtonReturnCustom";
import { TittleCustom } from "../../../components/TittleCustom";
import api_app from "../../../apis/api_app";
import withRouter from "../../../helpers/withRouter";
import SpinnerCustom from "../../../components/SpinnerCustom";
import { store } from "../../../redux/store";
import { extractTimeFromDateString, parseDateToDMYFormat, parseToYMD } from "../../../helpers/date_helpers";
import { useNavigate, useParams } from "react-router-dom";

const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

const AgendamentoConsulta = (props) => {
  let navigate = useNavigate();
  let params = useParams();
  const [pessoa, setPessoa] = useState({});
  const [pessoalogada, setPessoalogada] = useState(store.getState().user.user);
  const [agenda, setAgenda] = useState([]);
  const [especialidades, setEspecialidades] = useState([]);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([{ key: "0", value: "Selecione a especialidade...", disabled: true }]);
  const [hora, setHora] = useState([{ key: "0", value: "Selecione a data...", disabled: true }]);
  const [selectedData, setSelectedData] = useState('');
  const [idSelectedHora, setIdSelectedHora] = useState('');
  const [selectedEspecialidade, setSelectedEspecialidade] = useState('');
  const [confirmMessage, setConfirmMessage] = useState('');

  const handleChange = (event) => {
      const { name, value } = event.target;

      switch (name) {
        case 'selectedData': {
          const selectedAgenda = agenda.find((x) => x.id === parseInt(value));
          if (selectedAgenda) {
            const horas = agenda
              .filter((ag) => (
                parseToYMD(new Date(ag.data)) === parseToYMD(new Date(selectedAgenda.data))
                && new Date(ag.data) >= new Date()
                && ag.status_agenda_id === 1
              ))
              .map((ag) => ({
                key: ag.id?.toString() || '',
                value: extractTimeFromDateString(new Date(ag.data)),
                disabled: false
              }))
              .sort((a, b) =>  a.value > b.value ? 1 : -1);

            setHora(horas)
            setSelectedData(value)
            setIdSelectedHora('')
          } else {
            setHora([{ key: "0", value: "Selecione a data...", disabled: true }])
            setIdSelectedHora('')
            setSelectedData('')
          }
          break;
        }
        case 'selectedHora': {
          const selectedAgenda = agenda.find((x) => x.id === parseInt(value));
          setIdSelectedHora(selectedAgenda.id);
          break;
        }
        case 'selectedEspecialidade': {
          if (value === 'Selecione') {
            setSelectedEspecialidade("")
          } else {
            setSelectedEspecialidade(value)
          }
        }
        default:
          break;
      }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api_app.getPessoa(params.id);
        const responseAgenda = await api_app.getAgendaProfissional(params.id);
        const pessoa = response.data;
        const agenda = responseAgenda.data;
  
        const especialidades = pessoa.pessoa_especialidade.map((esp) => ({
          key: esp.especialidade_id?.toString(),
          value: esp?.especialidade?.especialidade?.toString() || '',
          disabled: false
        }));

        const datas =
          agenda
            .filter((ag) => (
              new Date(ag.data) >= new Date() && ag.status_agenda_id === 1
            ))
            .map((ag) => (
              {
                key: ag.id?.toString() || '',
                value: parseDateToDMYFormat(new Date(ag.data)),
                disabled: false
              }
            ));
        
        const data = Array.from(new Map(datas.map(item => [item.value, item])).values());
        const ordenedDatas = data.sort((a, b) =>  a.value > b.value ? 1 : -1)
  
        setPessoa(pessoa);
        setEspecialidades(especialidades);
        setData(ordenedDatas);
        setAgenda(agenda);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchData();
  }, [params.id]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!selectedData || !idSelectedHora) {
      setConfirmMessage('Por favor, selecione data e hora.');
      return;
    }

    if (!selectedEspecialidade) {
      setConfirmMessage('Por favor, selecione uma especialidade.')
      return;
    }

    const pacienteId = pessoalogada.id || '';
    if (!pacienteId) {
      setConfirmMessage('ID do paciente não encontrado.');
      return;
    }

    try {
      await api_app.patchAgenda(idSelectedHora, {
          paciente_id: pacienteId,
          id: parseInt(idSelectedHora),
          especialidade_id: parseInt(selectedEspecialidade),
          status_agenda_id: 3,
          agendado_por: parseInt(idSelectedHora),
      });
      setConfirmMessage('Consulta agendada com sucesso!');
      navigate('/agendamento-de-consulta');
    } catch (error) {
      console.error('Erro ao agendar consulta:', error);
      setConfirmMessage('Erro ao agendar consulta.');
    }
  }
  
  if (loading) {
    return <div className="flex justify-center items-center h-screen">
      <SpinnerCustom />
    </div>
  }

  return (
    <>
      <div className="flex items-center justify-between rounded-t dark:border-gray-600">
        <TittleCustom
          name="Agendamento de Consulta"
        />
        <ButtonReturnCustom
          link="/agendamento-de-consulta"
        />
      </div>
      <form onSubmit={handleSubmit}>
        <div className="flex flex-row justify-between mb-6 space-x-6">
          <div>
            <AboutCustumPage
              label={pessoa.nome || ""}
              name={pessoa.pessoa_profissional?.find(() => true)?.resumo_curriculo || ""}
              src={
                pessoa.pessoa_documento?.find(x => x.tipo_documento_id === 7) ?
                  REACT_APP_BASE_URL + "/pessoa-documento/documento/" + pessoa.pessoa_documento.find(x => x.tipo_documento_id === 7)?.id
                  : ''}
              pessoaprofission={pessoa.pessoa_fisica?.find(() => true) || {}}
            />
          </div>
          <div className="flex-grow">
            <div className="mb-6">
              <SelectCustom
                  label="Especialidade:"
                  name="selectedEspecialidade"
                  defaultValue="Selecione"
                  options={especialidades}
                  onChange={handleChange}
              />
            </div>
            <div className="grid gap-6 mb-6 md:grid-cols-2">
              <SelectCustom
                label="Data:"
                name="selectedData"
                defaultValue="Selecione"
                options={data}
                onChange={handleChange}
              />
              <SelectCustom
                label="Hora:"
                name="selectedHora"
                defaultValue="Selecione"
                options={hora}
                onChange={handleChange}
              />
            </div>
            <div className="mb-6">
              <CheckboxCustum
                label="Declaro estar ciente das condições listadas nos"
                name=""
              />
            </div>
            <div>
              <ButtonSaveCustom
                name="Confirmar"
                type="submit"
              />
            </div>
            {confirmMessage && (
              <div className="mt-4">
                <p className="mt-2 text-sm text-green-600 dark:text-green-500">{confirmMessage}</p>
              </div>
            )}
          </div>
        </div>
      </form>
    </>
  );
}

export default withRouter(AgendamentoConsulta);
