import React, { useState, useEffect } from 'react';
import { Menu } from "../Menu";
import FormPessoa from "./FormPessoa";
import { ListTelefone } from "./telefone/ListTelefone";
import { ListEmail } from "./email/ListEmail";
import { ListEndereco } from "./endereco/ListEndereco";
import { useSelector } from "react-redux";
import SpinnerCustom from '../../../../components/SpinnerCustom';
import { store } from '../../../../redux/store';
import FormFiles from './FormFiles';

// const LoadingSpinner = () => (
//     <div className="flex justify-center items-center h-screen">
//         <div className="loader"><SpinnerCustom /></div>
//     </div>
// );

export function Perfil() {


    return (
        <div>
            <div className="mb-3">
                <Menu />
            </div>
            <div>
                <div>
                    <FormPessoa  />
                </div>
            </div>
            <div>
                <FormFiles usuario_id={3} />
            </div>
            <div>
                <ListEndereco />
            </div>
            <div>
                <ListTelefone />
            </div>
            <div>
                <ListEmail />
            </div>
        </div>
    );
}
