import React, { useEffect, useRef } from "react";
import { Text as KonvaText, Transformer } from "react-konva";

const ResizableText = ({ text, isSelected, onChange }) => {
    const textRef = useRef();
    const trRef = useRef();
  
    useEffect(() => {
      if (isSelected) {
        trRef.current.nodes([textRef.current]);
        trRef.current.getLayer().batchDraw();
      }
    }, [isSelected]);
  
    return (
      <>
        <KonvaText
          {...text}
          ref={textRef}
          fill={text.color}
          draggable
          onDragEnd={(e) => {
            onChange({
              ...text,
              x: e.target.x(),
              y: e.target.y(),
            });
          }}
        />
        {isSelected && (
          <Transformer
            ref={trRef}
            boundBoxFunc={(oldBox, newBox) => {
              if (newBox.width < 30 || newBox.height < 20) {
                return oldBox;
              }
              return newBox;
            }}
            onTransformEnd={(e) => {
              const node = textRef.current;
              const newFontSize = node.fontSize() * node.scaleX();
              node.fontSize(newFontSize);
              node.scaleX(1);
              node.scaleY(1);
              onChange({
                ...text,
                fontSize: newFontSize,
              });
            }}
          />
        )}
      </>
    );
  };

export default ResizableText