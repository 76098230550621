import React, { useState } from "react";

export interface PropertiesCustom {
    label: string;
    value: string;
    name: string;
    disabled?: boolean;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    defaultValue?: string; // Valor padrão ('S' ou 'N')
}

export const ToggleCustom = (props: PropertiesCustom) => {
    const [selectedValue, setSelectedValue] = useState(props.defaultValue === 'S' ? 'N' : 'S');

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.checked ? 'N' : 'S';
        console.log(event.target.value);
        setSelectedValue(newValue);
        if (props.onChange) {
            props.onChange(event);
        }
    };

    return (
        <div>
            <label className="inline-flex items-center cursor-pointer">
                <input
                    type="checkbox"
                    name={props.name}
                    className="sr-only peer"
                    value={selectedValue} 
                    onChange={handleChange}
                    checked={selectedValue === 'N'} 
                    disabled={props.disabled}
                />
                <div
                    className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-900 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-900"
                ></div>
                <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">
                    {props.label}
                </span>
            </label>
        </div>
    );
};
