import React, { useRef } from "react";
import { KonvaColor, KonvaColorInput, KonvaColorSpan } from "../konvaSketchBoard/styles";

const KonvaColorPicker = ({ penColor, setPenColor  }) => {
    const inputColorRef = useRef(null);
    return (
        <KonvaColor
          onClick={() => inputColorRef.current && inputColorRef.current.click()}
        >
          <KonvaColorSpan
            style={{backgroundColor: `${penColor}`}}
          ></KonvaColorSpan>
          <KonvaColorInput
            ref={inputColorRef}
            type="color"
            onChange={(e) => setPenColor(e.target.value)}
          />
        </KonvaColor>
    )
}

export default KonvaColorPicker