import { useNavigate } from "react-router-dom";

export interface PropertiesCustom {
    "link"?: string,
}

export const ButtonReturnCustom = (props: PropertiesCustom) => {
    const navigate = useNavigate();
    const goBack = () => {
        if(props.link) {
            navigate(props.link);
        } else {
            navigate(-1);
        }
    }

    return <>
        <div  
           onClick={goBack} 
           className="text-white bg-blue-800 hover:bg-blue-900 focus:ring-4 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 cursor-pointer"
           style={{ backgroundColor: "#3b5179" }}         
        >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18" />
            </svg>
        </div>
    </>
}
