import React from "react";
import {
  KonvaBlur,
  KonvaClose,
  KonvaCloseIcon,
  KonvaContainer,
  KonvaModal,
  KonvaModalInner
} from "./styles";

const KonvaSketchModal = ({
    show,
    onBlurClick,
    onCloseClick,
    children,
    $overmodal,
    $fixedwidth
}) => {
    if (!show) return null

    return (
        <KonvaContainer $overmodal={$overmodal}>
          <KonvaModal>
            <KonvaModalInner $fixedwidth={$fixedwidth}>
              {onCloseClick &&
                <KonvaClose
                  onClick={() => onCloseClick(false)}
                >
                  <KonvaCloseIcon
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                  >
                    <path fill="#5c6370" d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z"></path>
                  </KonvaCloseIcon>
                </KonvaClose>
              }
              {children}
            </KonvaModalInner>
          </KonvaModal>
          <KonvaBlur
            onClick={() => onBlurClick && onBlurClick(false)}
          />
        </KonvaContainer>
    )
}

export default KonvaSketchModal