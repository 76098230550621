import { Component } from "react";
import { Menu } from "../Menu";
import { PlanoFinanceiro } from "./planoindividual/ListPlanoIndividual";
import { PlanoProfissional } from "./planoprofissional/ListPlanoProfissional";

export class Planos extends Component {
    render() {
        return <div>
            <Menu />
           
            <div>
               <PlanoFinanceiro />
            </div>
            <div>
               <PlanoProfissional />
            </div>
        </div>
    }
}