/*global  MdHub*/
/*global  MdSinapsePrescricao*/
import React, { useEffect, useState } from 'react';
import { TabBody } from './styles';
import api_app from '../../apis/api_app';
import { store } from '../../redux/store';
import { getLastName, mockExternalId } from '../../helpers/personalDataHelpers';
import { parseDateToDMYFormat } from '../../helpers/date_helpers';
import Notification from '../notification/notification';
import SpinnerCustom from '../SpinnerCustom';
import { getCidade } from '../../apis/memed_api';

const handleModuleInit = async (module, paciente, setIsLoading) => {
  if (module.name === "plataforma.prescricao") {
    await MdHub.command.send("plataforma.prescricao", "setPaciente", {
      idExterno: mockExternalId(paciente),
      nome: paciente.nome,
      cpf: paciente.cpf_cnpj.replace(/\D/g, ''),
      data_nascimento: parseDateToDMYFormat(paciente.dat_nascimento_fundacao),
      nome_social: paciente.nome_social || "",
      telefone: paciente?.pessoa_telefone?.telefone || "",
    });
    setIsLoading(false);
    MdHub.module.show("plataforma.prescricao");
  }
};

const attachMemedListeners = (paciente, setIsLoading) => {
  if (window.MdSinapsePrescricao) {
    MdSinapsePrescricao.event.add("core:moduleInit", (module) => handleModuleInit(module, paciente, setIsLoading));
  }
};

const detachMemedListeners = async (script) => {
  if (window.MdSinapsePrescricao) {
    script && document.body.removeChild(script);
    await MdHub.command.send("plataforma.sdk", "logout");
    MdHub.module.hide('plataforma.prescricao');
  }
};

export default function MemedTab({ paciente, agenda }) {
  const [memedToken, setMemedToken] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const pessoaProfissional = store.getState().user?.user;
  const [isLoading, setIsLoading] = useState(true);

  const getRelationshipsFromProfessional = async () => {
    const relationships = {};
    const enderecoPrincipal = pessoaProfissional?.pessoa_endereco?.find((endereco) => endereco.flg_principal === 'S') || null;
    const cidadeFromMemed = enderecoPrincipal && await getCidade(enderecoPrincipal.cidade);
    const especialidadeFromMemed = agenda?.especialidade?.memed_id;

    if (cidadeFromMemed) {
      relationships.cidade = {
        data: {
          type: "cidades",
          id: cidadeFromMemed?.data?.data[0].id
        }
      };
    };
    if (especialidadeFromMemed) {
      relationships.especialidade = {
        data: {
          type: "especialidades",
          id: especialidadeFromMemed
        }
      }; 
    };

    return relationships;
  };

  const getBoardInformationFromProfessional = () => {
    let especialidade;
    pessoaProfissional?.pessoa_especialidade.map((especialidade_id) => {
      if (especialidade_id.especialidade.id === agenda?.especialidade.id) 
        especialidade = especialidade_id;
    });
    return especialidade;
  };

  const createNewUserAndFetchMemedToken = async () => {
    try {
      const relationships = await getRelationshipsFromProfessional();
      const boardInformation = getBoardInformationFromProfessional();
      const payload = {
        data: {
          type: "usuarios",
          attributes: {
            external_id: mockExternalId(pessoaProfissional),
            nome: pessoaProfissional?.nome?.split(' ')[0],
            sobrenome: getLastName(pessoaProfissional?.nome),
            cpf: pessoaProfissional?.cpf_cnpj,
            board: {
              board_code: "CRM",
              board_number: boardInformation.num_especialidade_rqe,
              board_state: boardInformation.uf
            },
            email: pessoaProfissional?.pessoa_email?.[0]?.email,
            telefone: pessoaProfissional?.pessoa_telefone?.[0]?.telefone?.replace(/\D/g, '') || "",
            data_nascimento: parseDateToDMYFormat(pessoaProfissional?.dat_nascimento_fundacao)
          },
          ...(Object.keys(relationships).length > 0 && { relationships })
        }
      };

      const signUpUserResponse = await api_app.signUpPrescritorMemed(payload);
      setMemedToken(signUpUserResponse.data.data.attributes.token);
      return signUpUserResponse;
    } catch (error) {
      setIsLoading(false);
      setErrorMessage('Erro cadastrando usuário prescritor');
      throw error;
    }
  };  

  const fetchMemedTokenFromExistingUser = async () => {
    try {
      const response = await api_app.getPrescritorMemed(pessoaProfissional.cpf_cnpj?.replace(/\D/g, ''));
      setMemedToken(response.data.data.attributes.token);
      return response;
    } catch (error) {
      if (error.response && error.response.status === 500) {
        return createNewUserAndFetchMemedToken();
      } else {
        setIsLoading(false);
        setErrorMessage('Erro buscando token do usuário existente');
        throw error;
      }
    }
  };

  useEffect(() => {
    setIsLoading(true);
    const handleMemedToken = async () => {
      try {
        await fetchMemedTokenFromExistingUser();
      } catch (error) {
        console.error(error);
      }
    };

    let script;
    const insertMemedScript = () => {
      script = document.createElement("script");
      script.src = 'https://integrations.memed.com.br/modulos/plataforma.sinapse-prescricao/build/sinapse-prescricao.min.js';
      script.dataset.token = memedToken;
      script.dataset.container = "memed-tab-body";
      document.body.appendChild(script);

      script.onload = () => attachMemedListeners(paciente, setIsLoading);
    };

    handleMemedToken();
    if (memedToken) {
      insertMemedScript();
    }

    return () => {
      detachMemedListeners(script);
    };
  }, [memedToken]);

  return (
    <>
      <TabBody id="memed-tab-body" >
      {isLoading ? <SpinnerCustom /> : null}
      {errorMessage && <Notification mode="error" message={errorMessage} />}
      </TabBody>
    </>
  );
}
