import styled from "styled-components";

export const ProntuarioBox = styled.div`
    ul,
    ol {
      list-style-position: outside;
      padding-left: 30px;
      margin-left: 0;
    }

    ul {
        list-style: circle;
    }

    ol {
        list-style: decimal;
    }

    li {
      padding-left: 10px;
    }

    blockquote {
      margin-left: 0;
      padding-left: 30px;
    }
`