import React, { useRef } from "react";
import {
    KonvaModalButton,
    KonvaModalFooter,
    KonvaTitle
} from "../konvaSketchBoard/styles";
import {
    KonvaResizeContent,
    KonvaResizeInput,
    KonvaResizeInputMetric,
    KonvaResizeInputsContainer,
    KonvaResizeModalContainer
} from "./styles";

const KonvaResizeModal = ({ onCancel, onResize }) => {
    const inputWidthRef = useRef(null)
    const inputHeightRef = useRef(null)

    const handleOnResize = () => {
        if (inputHeightRef.current && inputWidthRef.current) {
          onResize({
            width: parseFloat(inputWidthRef.current.value > window.innerWidth * 0.85 - 75 ? window.innerWidth * 0.85 - 75 : inputWidthRef.current.value),
            height: parseFloat(inputHeightRef.current.value > window.innerHeight * 0.5 ? window.innerHeight * 0.5 : inputHeightRef.current.value)
          });
        }
    }

    return  (
        <KonvaResizeModalContainer>
            <KonvaTitle>Redimensionar quadro</KonvaTitle>
            <KonvaResizeContent>
                <KonvaResizeInputsContainer>
                    <KonvaResizeInput type="number" ref={inputWidthRef} />
                    <KonvaResizeInputMetric>px</KonvaResizeInputMetric>
                </KonvaResizeInputsContainer>
                <KonvaResizeInputsContainer>
                    <KonvaResizeInput type="number" ref={inputHeightRef} />
                    <KonvaResizeInputMetric>px</KonvaResizeInputMetric>
                </KonvaResizeInputsContainer>
            </KonvaResizeContent>
            <KonvaModalFooter>
                <KonvaModalButton
                    onClick={onCancel}
                    $variant="cancel"
                >
                    Cancelar
                </KonvaModalButton>
                <KonvaModalButton
                    onClick={handleOnResize}
                    $variant="add"
                >
                    Redimensionar
                </KonvaModalButton>
            </KonvaModalFooter>
        </KonvaResizeModalContainer>
    )
}

export default KonvaResizeModal