import React, {
  useRef,
  useState,
  useEffect,
  useCallback
} from "react";
import {
  Stage as KonvaStage,
  Layer as KonvaLayer,
  Line as KonvaLine
} from "react-konva";
import ResizableImage from "../resizableImage/ResizableImage";
import ResizableText from "../resizableText/ResizableText";
import KonvaSketchModal from "../KonvaSketchModal";
import {
  KonvaBoard,
  KonvaCommands,
  KonvaContainer,
  KonvaSeparator,
  KonvaModalButton,
  KonvaModalFooter,
  KonvaTitle
} from "./styles";
import KonvaUndoButton from "../shared/KonvaUndoButton";
import KonvaRedoButton from "../shared/KonvaRedoButton";
import KonvaTextButton from "../shared/KonvaTextButton";
import KonvaPenButton from "../shared/KonvaPenButton";
import KonvaEraserButton from "../shared/KonvaEraserButton";
import KonvaImageButton from "../shared/KonvaImageButton";
import KonvaColorPicker from "../shared/KonvaColorPicker";
import KonvaResizeModal from "../konvaResizeModal/KonvaResizeModal";
import KonvaResizeButton from "../shared/KonvaResizeButton";
import KonvaInputTextModal from "../konvaInputTextModal/KonvaInputTextModal";

const KonvaSketchBoard = ({ onCloseButton, setImageUri }) => {
  const [lines, setLines] = useState([]);
  const [isDrawing, setIsDrawing] = useState(false);
  const [texts, setTexts] = useState([]);
  const [text, setText] = useState("");
  const [addingText, setAddingText] = useState(false);
  const [tool, setTool] = useState("pen");
  const [selectedText, setSelectedText] = useState(null);
  const [canvasDimensions, setCanvasDimensions] = useState({
    width: window.innerWidth * 0.85 - 75,
    height: window.innerHeight * 0.5,
  });
  const [history, setHistory] = useState([]);
  const [historyStep, setHistoryStep] = useState(0);
  const [images, setImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [penColor, setPenColor] = useState("#000000");
  const [infoModal, setInfoModal] = useState(null);
  // const [isBlurring, setIsBlurring] = useState(false);
  const stageRef = useRef(null);
  const canvasRef = useRef(null);

  useEffect(() => {
    setHistory([{ lines }]);
    handleResize();
    window.addEventListener("resize", handleResize);
    window.addEventListener("keydown", handleKeyDown)
    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("keydown", handleKeyDown)
    };
  }, []);

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown)
    return () => {
      window.removeEventListener("keydown", handleKeyDown)
    };
  }, [selectedText, selectedImage]);

  const handleResize = useCallback(() => {
    setCanvasDimensions({
      width: window.innerWidth * 0.85 - 75,
      height: window.innerHeight * 0.5,
    });
  }, []);

  const handleUploadObjectImage = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      const newImage = {
        id: `image-${Date.now()}`,
        src: reader.result,
        x: 50,
        y: 50,
        width: 100,
        height: 100,
        rotation: 0,
      };
      setImages([...images, newImage]);
    };
    reader.readAsDataURL(file);
  };

  const handleTextChange = (newAttrs) => {
    const updatedTexts = texts.map((textItem) =>
      textItem.id === newAttrs.id ? newAttrs : textItem
    );
    setTexts(updatedTexts);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Delete") {
      if (selectedText) {
        const newTexts = texts.filter((textItem) => !(textItem.id === selectedText.attrs.id));
        setTexts(newTexts);
        setSelectedText(null);
      }
      if (selectedImage) {
        setImages(images.filter((img) => img.id !== selectedImage));
        setSelectedImage(null);
      }
    }
    if (e.key === "Escape") {
      setSelectedText(null)
      setSelectedImage(null);
    }
  }

  const handleMouseDown = (e) => {
    const target = e.target;
    if (target.getClassName() === 'Text') {
      setSelectedText(target);
      return;
    }
    if (target.getClassName() === 'Image' && !addingText && (selectedImage || selectedText)) {
      if (target.attrs.src) {
        return;
      } else {
        setSelectedImage(null)
        setSelectedText(null);
      }
      return;
    }
    if (addingText) {
      const stage = e.target.getStage();
      const pointerPosition = stage.getPointerPosition();
      const newText = {
        id: `text-${Date.now()}`,
        text: text,
        x: pointerPosition.x,
        y: pointerPosition.y,
        fontSize: 20,
        color: penColor
      };
      const newTexts = [...texts, newText];
      setTexts(newTexts);
    } else {
      setIsDrawing(true);
      const pos = e.target.getStage().getPointerPosition();
      const newLine = { tool: tool, points: [pos.x, pos.y], color: penColor }
      if (
        JSON.stringify(lines[lines.length - 1]) === JSON.stringify(newLine)
      ) {
        return;
      }
      setLines([...lines, newLine]);
    }
  };

  const handleMouseMove = (e) => {
    const target = e.target;
    if (!isDrawing) {
      return;
    }
    const stage = target.getStage();
    const point = stage.getPointerPosition();
    let lastLine = lines[lines.length - 1];
    lastLine.points = lastLine.points.concat([point.x, point.y]);
    lines.splice(lines.length - 1, 1, lastLine);
    setLines(lines.concat());
  };

  const handleMouseUp = (e) => {
    setIsDrawing(false);
    if (addingText || e.target.getClassName() !== "Line") {
      setAddingText(false);
      return;
    }
    saveHistory(lines);
  };

  const saveHistory = (newLines) => {
    const newHistory = history.slice(0, historyStep + 1);
    const currentState = {
      lines: newLines || lines,
    };
    setHistory([...newHistory, currentState]);
    setHistoryStep(historyStep + 1);
  };

  const handleUndo = () => {
    if (historyStep === 0) {
      return;
    }
    const previousState = history[historyStep - 1];
    setLines(previousState.lines);
    setHistoryStep(historyStep - 1);
  };

  const handleRedo = () => {
    if (historyStep === history.length - 1) {
      return;
    }
    const nextState = history[historyStep + 1];
    if (nextState) {
      setLines(nextState.lines);
      setHistoryStep(historyStep + 1);
    }
  };

  const handleGetImage = () => {
    const uri = stageRef.current.getStage().toDataURL();
    setImageUri(uri)
    onCloseButton(false)
  };

  const handleOnCancel = () => setInfoModal(false);
  const handleOnAdd = () => {
    setAddingText(true);
    setInfoModal(false);
  };
  const openInputTextModal = () => setInfoModal(<KonvaInputTextModal onChange={setText} onCancel={handleOnCancel} onAdd={handleOnAdd} />)

  const handleOnResize = (canvasDimensions) => {
    setCanvasDimensions(canvasDimensions);
    setInfoModal(false);
  };
  const openResizeModal = () => setInfoModal(<KonvaResizeModal onCancel={handleOnCancel} onResize={handleOnResize} />)

  return (
    <>
      <KonvaContainer>
        <KonvaTitle>Inserir imagem editada</KonvaTitle>
        <KonvaCommands>
          <KonvaUndoButton handleUndo={handleUndo} isEnabled={historyStep === 0} />
          <KonvaRedoButton handleRedo={handleRedo} isEnabled={historyStep === history.length - 1} />
          <KonvaSeparator />
          <KonvaTextButton openInputTextModal={openInputTextModal} />
          <KonvaSeparator />
          <KonvaPenButton setTool={() => setTool("pen")} tool={tool} />
          <KonvaEraserButton setTool={() => setTool("eraser")} tool={tool} />
          <KonvaSeparator />
          <KonvaColorPicker penColor={penColor} setPenColor={setPenColor} />
          <KonvaSeparator />
          <KonvaImageButton handleUploadObjectImage={handleUploadObjectImage} />
          <KonvaSeparator />
          <KonvaResizeButton openResizeModal={openResizeModal} />
        </KonvaCommands>
        <KonvaBoard ref={canvasRef}>
          <KonvaStage
            width={canvasDimensions.width}
            height={canvasDimensions.height}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            ref={stageRef}
          >
            <KonvaLayer>
              {images.map((imageItem, i) => (
                <ResizableImage
                  key={i}
                  image={imageItem}
                  isSelected={imageItem.id === selectedImage}
                  onSelect={() => {
                    setSelectedImage(imageItem.id);
                  }}
                  onChange={(newAttrs) => {
                    const updatedImages = images.slice();
                    updatedImages[i] = newAttrs;
                    setImages(updatedImages);
                  }}
                />
              ))}
            </KonvaLayer>
            <KonvaLayer>
              {lines.map((line, i) => (
                <KonvaLine
                  key={i}
                  points={line.points}
                  stroke={line.color}
                  strokeWidth={5}
                  tension={0.5}
                  lineCap="round"
                  globalCompositeOperation={
                    line.tool === "eraser" ? "destination-out" : "source-over"
                  }
                />
              ))}
              {texts.map((textItem, i) => (
                <ResizableText
                  key={i}
                  text={textItem}
                  isSelected={textItem.id === selectedText?.attrs?.id}
                  onChange={handleTextChange}
                />
              ))}
            </KonvaLayer>
          </KonvaStage>
        </KonvaBoard>
        <KonvaModalFooter>
          <KonvaModalButton
            onClick={() => onCloseButton(false)}
            $variant="cancel"
          >
            Cancelar
          </KonvaModalButton>
          <KonvaModalButton
            onClick={handleGetImage}
            $variant="add"
          >
            Adicionar
          </KonvaModalButton>
        </KonvaModalFooter>
      </KonvaContainer>
      {infoModal &&
        <KonvaSketchModal
          show={infoModal}
          onCloseClick={setInfoModal}
          overModal
          fixedWidth
        >
          {infoModal}
        </KonvaSketchModal>
      }
    </>
  );
};

export default KonvaSketchBoard;
