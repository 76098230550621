import styled from 'styled-components';

export const KonvaTextModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 300px;
  max-height: 200px;
`;

export const KonvaTextModalContent = styled.textarea`
  width: calc(100% - 15px);
  min-width: 50%;
  max-width: calc(100% - 15px);
  min-height: 80px;
  background-color: #ffffff;
  border: 1px solid #959292;
  border-radius: 7px;
  color: black;
  margin-bottom: 20px;
`;