import React, { Component } from "react";
import { TittleCustom } from "../../../../../components/TittleCustom";
import { ButtonReturnCustom } from "../../../../../components/ButtonReturnCustom";
import { ButtonSaveCustom } from "../../../../../components/ButtonSaveCustom";
import { pessoa_telefoneModel } from "../../../../../resource/request";
import InputCustom from "../../../../../components/InputCustom";
import withRouter from "../../../../../helpers/withRouter";
import { store } from "../../../../../redux/store";
import api_app from "../../../../../apis/api_app";
import { ToggleCustom } from "../../../../../components/ToggleCustom";

interface State {
    telefone: pessoa_telefoneModel
    telefonePrincipal: pessoa_telefoneModel
    msg: string,
    isSubmitted: boolean;

}

export default withRouter(class CreateTelefone extends Component<any> {

    state: State = {
        telefone: {
            flg_principal: "N",
        } as pessoa_telefoneModel,
        telefonePrincipal: {} as pessoa_telefoneModel,
        msg: "",
        isSubmitted: false,
    }

        componentDidMount() {
            store.getState().user.user.pessoa_telefone.map(telefone => {
                if (telefone.flg_principal === "S") {
                    this.setState({telefonePrincipal: telefone });
                }
            })
        }

    handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = event.target;
        this.setState({
            telefone: {
                ...this.state.telefone,
                [name]: value
            }
        });
    };

    handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        this.state.telefone.pessoa_id = store.getState().user.user.id;

        api_app.criartTelefone(this.state.telefone).then(x => {
            const msg = "Seu telefone foi cadastrado com com suscesso!";
            this.setState({ msg, isSubmitted: true });
            this.props.updateStore();
        });
    };

    render() {
        return <>
            <form onSubmit={this.handleSubmit}>
                <div className="flex items-center justify-between rounded-t dark:border-gray-600">
                    <TittleCustom
                        name="Adicionar Telefone"
                    />
                    <ButtonReturnCustom
                        link="/perfil"
                    />
                </div>
                <div className="grid gap-6 mb-6">
                    <InputCustom
                        label="Telefone:"
                        value={this.state?.telefone?.telefone || ''}
                        type="text"
                        mask="(99) 9 99999999"
                        name="telefone"
                        placeholder="(00) 0 00000000"
                        onChange={this.handleChange}
                        required
                    />
                    <InputCustom
                        label="Descrição:"
                        value={this.state?.telefone?.observacao || ''}
                        type="text"
                        name="observacao"
                        placeholder="Descrição"
                        onChange={this.handleChange}
                    />
                    <ToggleCustom
                        label="Telefone Principal?"
                        value={this.state?.telefone.flg_principal || 'S'}
                        name='flg_principal'
                        onChange={this.handleChange}
                        disabled={this.state?.telefonePrincipal?.id !== undefined ? true : false}
                        defaultValue={this.state?.telefone.flg_principal || 'N'}
                    />
                </div>
                <div className="flex items-center mt-6 space-x-4 rtl:space-x-reverse">
                    {!this.state.isSubmitted && (
                        <ButtonSaveCustom
                            type="submit"
                            name="Salvar"
                        />
                    )}
                </div>
                <p className="mt-2 text-sm text-green-600 dark:text-green-500">{this.state.msg}</p>
            </form>
        </>
    }
}
)



