import axios from "axios";

const MEMED_BASE_URL = "https://integrations.api.memed.com.br";

const memed_api = axios.create({
  baseURL: MEMED_BASE_URL,
});

memed_api.interceptors.request.use(async (config) => {
  return config;
});

export const getCidade = async (cidade) => {
  const response = await memed_api.get(`/v1/cidades?filter[q]=${cidade}`);
  return response;
}