import React, { useState, useEffect } from "react";
import { TittleCustom } from "../../../../../components/TittleCustom";
import { ButtonReturnCustom } from "../../../../../components/ButtonReturnCustom";
import { ButtonSaveCustom } from "../../../../../components/ButtonSaveCustom";
import InputCustom from "../../../../../components/InputCustom";
import { pessoa_emailModel, pessoaModel } from "../../../../../resource/request";
import { store } from "../../../../../redux/store";
import withRouter from "../../../../../helpers/withRouter";
import api_app from "../../../../../apis/api_app";
import { ToggleCustom } from "../../../../../components/ToggleCustom";
import SpinnerCustom from "../../../../../components/SpinnerCustom";
import { useDispatch } from "react-redux";
import { createUser } from "../../../../../redux/user/slice";

const EditEmail = (props: any) => {
    const [email, setEmail] = useState<pessoa_emailModel>({} as pessoa_emailModel);
    const [msg, setMsg] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
    const [emailPrincipal, setEmailPrincipal] = useState<pessoa_emailModel>({} as pessoa_emailModel);
    const [user, setUser] = useState<pessoaModel>(store.getState().user.user);
    let dispach = useDispatch();

    const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = event.target;
        setEmail((prevEmail) => ({
            ...prevEmail,
            [name]: value,
        }));
    };

    const updateStore = () => {     
        api_app.findByPessoa(emailPrincipal.email).then(result => {
            if (result) {
              dispach(createUser(result));
            } 
          });   
    }

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        api_app.atualizarEmail(email.id, email).then((x) => {
            setMsg("Seu email foi atualizado com sucesso!");
            setIsSubmitted(true);
            updateStore();
        });
    };

    useEffect(() => {
        const id = props.router.params.id;
        const userEmails = user.pessoa_email;

        userEmails.forEach((email) => {
            if (email.id.toString() === id.toString()) {
                setEmail(email);
                setIsLoading(false);
            }
            if (email.flg_principal === "S" && email.id.toString() !== id.toString()) {
                setEmailPrincipal(email);
            }
        });
    }, []);

    if (isLoading) {
        return <div><SpinnerCustom /></div>;
    }

    return (
        <>
            <form onSubmit={handleSubmit}>
                <div className="flex items-center justify-between rounded-t dark:border-gray-600">
                    <TittleCustom name="Alterar E-mail" />
                    <ButtonReturnCustom link="/perfil" />
                </div>
                <div className="grid gap-6 mb-6 ">
                    <InputCustom
                        label="E-mail:"
                        value={email?.email || ''}
                        type="text"
                        name="email"
                        placeholder="E-mail"
                        onChange={handleChange}
                    />
                    <InputCustom
                        label="Observação:"
                        value={email?.observacao || ''}
                        type="text"
                        name="observacao"
                        placeholder="Observação"
                        onChange={handleChange}
                    />
                    <ToggleCustom
                        label={
                            emailPrincipal?.email !== undefined
                                ? emailPrincipal?.email + " é seu email principal!"
                                : "e-mail Principal?"
                        }
                        value={email?.flg_principal || 'S'}
                        name="flg_principal"
                        onChange={handleChange}
                        disabled={emailPrincipal?.email !== undefined}
                        defaultValue={email?.flg_principal || 'N'}
                    />
                </div>
                <div className="flex items-center mt-6 space-x-4 rtl:space-x-reverse">
                    {!isSubmitted && (
                        <ButtonSaveCustom
                            type="submit"
                            name="Salvar"
                        />
                    )}
                </div>
                <p className="mt-2 text-sm text-green-600 dark:text-green-500">{msg}</p>
            </form>
        </>
    );
};

export default withRouter(EditEmail);
